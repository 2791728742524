.form-steps {
    width: 90%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--breezi-blue);
}

.step-sep {
    width: 30px;
    height: 4px;
    background: var(--white);
}

.step-node {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--white);
    border-radius: 100%;
    cursor: pointer;
}

.step-node img {
    height: 22.5px;
}

.active-step {
    width: 50px;
    height: 50px;
    background: var(--breezi-blue);
}

.active-step img {
    height: 30px;
    margin-top: 4px;
}

.past-step {
    background: var(--prim-transparent);
}

@keyframes grow-square {
    100% {
        border-radius: 0px;
        width: 55px;
        height: 55px;
        background: var(--bg);
    }
}

.step-node small {
    animation: text-appear .2s forwards;
    animation-delay: .4s; 
    opacity: 0;
}

@keyframes text-appear {
    100% {
        opacity: 1;
    }
}