.hvac-panel {
    width: 88%;
}

.hvac-body {
    width: 100%;
    height: 100%;
    margin-top: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.equip-bank {
    width: 100%;
    height: 160px;
    border-bottom: 3px solid var(--equip);
    animation: slidebank .75s linear forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
}

.equip-bank-node {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    padding: 5%;
}

.equip-bank-node:hover {
    background: var(--sec-transparent);
}

.equip-bank-container {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.equip-bank-node img {
    width: 70%;
}

.equip-config-tog {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--white);
    border-radius: 5px;
    position: absolute;
    right: 1%;
    cursor: pointer;
}

.equip-config-tog:hover {
    background: var(--equip);
}

.equip-config-tog img {
    height: 70%;
}

.back-arr:hover {
    background: var(--equip);
}

@keyframes slidebank {
    0% {
        margin-top: -400px;
    }
    100% {
        margin-top: 0;
    }
}

@keyframes slidebankrev {
    0% {
        margin-top: 0;
    }
    100% {
        margin-top: -400px;
    }
}

.equip-content {
    width: 95%;
    height: calc(100% - 280px);
    background: var(--bg);
    margin-top: 1.75%;
    border: 1px solid var(--white);
    border-radius: 5px;
    animation: .4s shrinkbank linear forwards;
    position: relative;
}

@keyframes growpanel {
    0% {
        height: 65%;
    }
    100% {
        height: 85%;
    }
}

@keyframes shrinkpanel {
    0% {
        height: 85%;
    }
    100% {
        height: 65%;
    }
}

.equip-sections {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.equip-section {
    height: 65%;
    max-width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    border: 2px dashed var(--bg-frame);
    margin: 20px;
    padding: 1%;
    padding-bottom: 4%;
    margin-top: 40px;
}

.fan-section {
    flex-wrap: wrap;
}

.elem-wrapper {
    height: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    align-content: center;
}

.fan-wrapper {
    flex-wrap: wrap;
    /* width: 50%; */
    /* flex-wrap: wrap;
    justify-content: flex-start; */
}

.fan-cluster {
    min-width: 140px;
    max-width: 46%;
    min-height: 150px;
    max-height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 5px;
    border: 2px solid var(--bg-frame);
    margin: 3px;
}

.ap-section {
    min-width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ap-ghost-container {
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ap-ghost {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: var(--breezi-recessive);
    border: 1px solid var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ap-ghost img {
    width: 30px;
}

.upstream {
    left: 5px;
}

.downstream {
    right: 5px;
}

.sec-label {
    position: absolute;
    bottom: 2px;
    color: var(--bg-frame);
}

.elem-container {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec-elem {
    display: flex;
    /* max-height: 200px; */
    width: 60px;
    height: 285px;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 14px;
    margin: 3px;
}

.sec-elem img {
    height: 100%;
}

.fan-elem {
    width: 45%;
    height: 50%;
    /* width: 50px; */
}

.fan-elem img {
    height: 50px;
}

.bag-filter-elem {
    width: 90px;
}

.bag-filter-elem img {
    height: 285px;
    width: 100px;
}

.heating-coil-elem, .cooling-coil-elem {
    width: 40px;
    height: 285px;
}


.layout {
    background-color: #f0f0f0;
}

.airflow-sel-title {
    font-weight: lighter;
    margin-bottom: 20px;
}

.airflow-sel {
    display: flex;
    width: 40%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10%;
}

.airflow-sel-node {
    width: 30%;
    height: 110px;
    color: var(--breezi-blue); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    border: 1px solid var(--breezi-blue);
    border-radius: 5px;
    cursor: pointer;
}

.airflow-sel-node:hover {
    background: var(--breezi-recessive);
    color: var(--white);
}

.airflow-sel-node h1 {
    margin: 0;
}

.bank-divide {
    width: 2px;
    height: 100%;
    background: var(--breezi-blue);
    margin-left: 40px;
    margin-right: 40px;
}

.hide-grid:hover {
    border: 2px solid var(--prim);
    color: var(--prim);
}

.equip-ap-bank {
    height: 160px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    padding-left: 20px;
    margin-right: 10px;
}

.equip-ap {
    min-width: 120px;
    width: 120px;
    min-height: 120px;
    height: 120px;
    margin-right: 1%;
    border-radius: 5px;
    border: 2px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: grab;
    /* margin: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--breezi-blue); */
}

.equip-ap img {
    height: 60%;
    margin-bottom: 5px;
}

.grid-tog {
    position: absolute;
    top: 2%;
    right: 20px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: none;
    cursor: pointer;
}

.tog-node {
    width: 50px;
    height: 50px;
    border: 1px solid var(--white);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tog-node img {
    width: 75%;
}

.grid-tog:hover .tog-node {
    background: var(--prim-transparent);
}

.tog-text {
    display: none;
}

.exp-tog .tog-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    animation: slidetog .25s forwards;
    background: var(--breezi-recessive);
}

.exp-tog h6 {
    opacity: 0;
    animation: fadetog .25s forwards;
    animation-delay: .25s;
}

@keyframes slidetog {
    0% {
        width: 0;
    }
    100% {
        width: 130px;
    }
}

@keyframes fadetog {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.config-tog:hover .tog-node {
    background: var(--cancel-transparent);
}

.ghost-section {
    background: rgba(211, 211, 211, 0.2);
    border: 1px solid var(--white);
    width: 210px;
    display: flex;
    flex-direction: column;
}

.ghost-section img {
    width: 50%;
}

.ap-equip-node {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ap-equip-rdg {
    width: 90px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--breezi-blue);
    border-radius: 5px;
    overflow: hidden;
    margin: 3px;
    position: relative;
    cursor: pointer;
}

.ap-equip-head {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-blue);
}

.ap-equip-head img {
    height: 80%;
}

.ap-equip-rdg h6 {
    width: 55%;
    text-align: center;
    font-size: 1.2em;
    font-weight: lighter;
}

.tt-prop-form {
    position: absolute;
    z-index: 1000;
    width: 200px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--window);
    border-radius: 5px;
    border: 1px solid var(--breezi-blue);
}

.tt-prop-form small {
    color: var(--breezi-blue);
}

.tt-btns {
    margin-top: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tt-btn {
    width: 50px;
    height: 30px;
    border: 1px solid var(--white);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--cancel);
    margin-left: 10px;
    margin-right: 10px;
}

.tt-btn:hover {
    background: var(--cancel-transparent);
    color: var(--white);
}

.tt-yes {
    color: var(--prim);
}

.tt-yes:hover {
    background: var(--prim-transparent);
    color: var(--white);
}

.tt-up {
    left: -100px;
}

.tt-down {
    right: -100px;
}

.equip-elem-overlay {
    border-radius: 5px;
    border: 1px solid var(--breezi-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);
    cursor: pointer;
}

.equip-elem-overlay:hover {
    background: rgba(0, 153, 191, 0.5);
}

.diff-check {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 5%;
    border-radius: 100%;
    border: 1px solid var(--white);
    background: var(--breezi-blue);
    display: flex;
    justify-content: center;
    align-items: center;
}

.diff-check img {
    width: 30px;
}

.diff-container {
    position: absolute;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 15%;
}

.diff-node {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    min-width: 150px;
    overflow: visible;
}

.row-diff {
    margin-top: 30px;
    flex-direction: row;
}

.elem-container {
    /* height: 70%; */
}


.mob-ap-equip-rdg {
    width: 100px;
    height: 40px;
    display: flex;
    flex-direction: row;
    margin: 5px;
    border-radius: 5px;
    border: 2px solid var(--breezi-blue);
}

.good-equip-rdg {
    border: 2px solid var(--prim-transparent);
}

.good-equip-rdg .ap-equip-head {
    background: var(--prim-transparent);
}

.bad-equip-rdg {
    border: 2px solid var(--cancel);
}

.bad-equip-rdg .ap-equip-head {
    background: var(--cancel);
}

.crit-equip-rdg {
    border: 2px solid var(--crit);
}

.crit-equip-rdg .ap-equip-head {
    background: var(--crit);
}

.airflow-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.airflow-dir {
    width: 300px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0%;
    left: calc(50% - 150px)
}

.airflow-dir img {
    height: 30px;
    margin: 20px;
}

.tog-sleeve {
    display: flex;
    flex-direction: row;
    left: 10px;
    justify-content: flex-start;
    top: 5px;
    width: 100%;
    position: relative;
}

.sleeve-node {
    width: 40px;
    margin: 5px;
    opacity: 0.4;
    border: 1px solid var(--white);
    padding: 3px;
    border-radius: 4px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sleeve-node img {
    height: 28px;
}

.sleeve-node:hover, .active-sleeve-node {
    opacity: 1;
    border: 1px solid var(--prim);
}

.sleeve-config {
    position: absolute;
    right: 1.5%;
    width: 230px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.zone-sleeve-node {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px 0 5px;
}

.sleeve-node h2 {
    margin: 0 5px 0 0;
}

.inactive-sleeve-node:hover {
    opacity: 0.4;
    border: 1px solid var(--white);
    cursor: not-allowed;
}

.equip-trends {
    width: 96%;
    height: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;
}

.equip-trends-col {
    margin-top: 40px;
    width: 80%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--window);
    border-radius: 7px;
    position: relative;
    overflow: hidden;
}

.equip-util-col {
    width: 19%;
    height: 85%;
    background: none;
    justify-content: space-between;
}

.equip-trends-header {
    width: 100%;
    height: 20%;
}

.equip-trends-snapshot {
    height: 41%;
    width: 96%;
    margin: 0;
    border: 3px solid darkgreen;
    justify-content: flex-start;
}

.equip-trends-alerts {
    height: 55%;
    width: 96%;
    margin: 0;
    border: 3px solid var(--breezi-blue);
    justify-content: flex-start;
}

.equip-util-header {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.equip-trends-snapshot .equip-util-header {
    background: darkgreen;
}

.equip-trends-alerts .equip-util-header {
    background: var(--breezi-blue);
}

.equip-snapshot-graphic {
    width: 85;
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.equip-alerts-body {
    width: 100%;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
}

.equip-trend-power {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 4%;
    right: 4%;
}

.equip-trend-power img {
    height: 20px;
    width: auto;
}

.equip-diff-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
}

@media(max-width:1810px) {
    .ap-equip-rdg {
        width: 80px;
    }
}


@media(max-width:1200px) {
    .ap-section {
        max-width: 25px;
        min-width: 25px;
    }
    .ap-equip-node {
        height: 80%;
        width: 25px;
    }
    .ap-equip-rdg {
        height: 50px;
        width: 25px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .ap-equip-head {
        width: 100%;
        height: 50%;
        margin: 0;
    }
    .ap-equip-rdg h6 {
        height: 50%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .diff-equip-rdg {
        width: 80px;
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 2px solid var(--breezi-blue);
        border-radius: 5px;
        overflow: hidden;
        margin: 3px;
        position: relative;
    }
    .diff-equip-rdg .ap-equip-head {
        height: 100%;
        width: 45%;
    }

    .fan-wrapper {
        height: 70%;
    }
    .fan-cluster {
        min-width: 100px;
        max-width: 20%;
        min-height: 100px;
        max-height: 100px;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-top: 3%;
    }
    .fan-elem {
        width: 40px;
        height: 40px;
    }
    .fan-elem img {
        width: 30px;
        height: auto;
    }
    .equip-section {
        /* border: 2px solid gray; */
    }
    .sec-elem img {
        height: 80%;
    }
}

@media(min-height:1000px) {
    .diff-container {
        bottom: 25%;
    }
}

@media(max-height:880px) {
    .diff-node {
        flex-direction: row;
    }
}


.equip-panel {
    width: 80%;
    height: 90%;
    background: var(--window);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
}

.hvac-content {
    width: 98%;
    margin-top: 1%;
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: var(--bg);
    border-radius: 5px;
    border: 1px solid gray;
    position: relative;
}

.hvac-sections {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hvac-section {
    max-width: 29.5%;
    height: 80%;
    border: 2px dashed gray;
    border-radius: 5px;
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.sec-title {
    position: absolute;
    bottom: -3%;
    font-weight: lighter;
    color: gray;
    font-size: .8em;
}

.hvac-sec-main {
    width: 100%;
    min-width: 300px;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hvac-ap {
    width: 110px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.hvac-diff-nodes img {
    width: 20px;
}

.hvac-elems {
    width: 100%;
    min-width: 120px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    /* margin-top: 15%; */
}

.hvac-elem {
    height: auto;
    margin: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.hvac-elem img {
   height: 280px;
   max-width: 100px;
   width: auto;
}

.hvac-footer {
    width: 100%;
    overflow: visible;
    height: 15%;
}

.hvac-fan {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: visible;
    /* width: 50%; */
}

.hvac-fan img {
    height: 200px;
    width: auto;
}

.hvac-bank-node {
    min-width: 130px;
    max-width: 130px;
    min-height: 130px;
    max-height: 130px;
    width: 130px;
    height: 130px;
    margin: .5%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    cursor: grab;
}

.hvac-bank-node img {
    height: 55%;
}

.hvac-bank-node small {
    margin-top: 10px;
}

.hvac-ghost {
    width: 200px;
    border-radius: 5px;
    height: 95%;
    border: 3px dashed var(--white);
    background: rgba(111, 111, 111, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hvac-ghost img {
    width: 50px;
    margin-bottom: 10px;
}
.hvac-ghost h4, .hvac-ghost h1 {
    margin: 2px;
}

.hvac-cards {
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* .hvac-fan-cluster { */
    /* width: 150px;
    height: 150px; */
    /* max-width: 45%;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid var(--gray);
    border-radius: 5px;
    margin: 5px;
}
.hvac-cluster-fan {
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hvac-fan-cluster img {
    height: 40px;
} */

.hvac-fan-clusters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.hvac-fan-cluster {
    width: 105px;
    height: 105px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid var(--equip);
    margin: 2%;
    border-radius: 5px;
}

.hvac-cluster-fan {
    width: 46%;
    height: 46%;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hvac-cluster-fan img {
    width: 80%;
    height: auto;
    margin-bottom: 10%;
}

.hvac-diff-sel {
    width: 140px;
    height: 100px;
    border: 2px solid var(--breezi-blue);
    border-radius: 5px;
    background: var(--window);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    animation: flashsel .5s forwards;
}

@keyframes flashsel {
    25% {
        background: var(--breezi-blue);
    }
    50% {
        background: var(--window);
    }
    75% {
        background: var(--breezi-blue);
    }
    100% {
        background: var(--window);
    }
}

.hvac-diff-opts {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5px;
}

.hvac-diff-opts h5 {
    margin: 0;
}

.hvac-opt {
    width: 40%;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 5%;
    cursor: pointer;
}

.hvac-opt h6 {
    margin: 0;
}

.hvac-footer {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-stat;
    align-items: center;
}

.hvac-diff {
    width: 250px;
    max-width: 90%;
    padding-top: 5px;
}

.hvac-diff-nodes {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hvac-diff-nodes .ap-equip-rdg {
    width: 100px;
}

.del-elem {
    border: 2px solid var(--crit);
}

.expand-close {
    position: absolute;
    top: 5px;
    right: 8px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    cursor: pointer;
}

.expand-close:hover {
    background: var(--breezi-blue);
    border: 2px solid var(--breezi-blue);
    top: 3px;
    right: 6px;
}

.airflow {
    width: 150px;
    height: 40px;
    border: 2px solid gray;
    /* border-radius: 20px; */
    position: absolute;
    left: calc(50% - 75.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: gray;
}

.airflow:hover {
    background: var(--equip);
    color: var(--white);
}

.split-airflow:hover {
    background: none;
}

.airflow h4 {
    font-weight: lighter;
}

.airflow-half {
    width: 47.5%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--equip);
}

.airflow-half:hover {
    background: var(--breezi-blue);
}

.airflow-left {
    /* border-top-left-radius: 15px;
    border-bottom-left-radius: 15px; */
    margin-right: 1%;
}

.airflow-right {
    /* border-top-right-radius: 15px;
    border-bottom-right-radius: 15px; */
    margin-left: 1%;
}

.airflow img {
    height: 20px;
    width: 30px;
}

.active-airflow {
    justify-content: space-between;
    border: 2px solid var(--equip);
}

.active-airflow h4 {
    width: 47.5%;
    color: var(--equip);
}

.active-airflow .airflow-half:hover {
    background: var(--equip);
}

.active-airflow:hover h4 {
    color: var(--bg);
}

.active-airflow .airflow-right {
    margin-right: 3px;
}

.active-airflow .airflow-left {
    margin-left: 3px;
}

.snapshot-on-off {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.snapshot-on-off img {
    width: 100%;
}

.equip-on-off {
    position: absolute;
    right: 10px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.equip-on-off img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-top: 7.5px;
}

.equip-on-off h3 {
    margin-bottom: 8px;
}

.airflow-static {
    width: 60px;
    height: 30px;
    background: var(--equip);
    position: absolute;
    left: calc(50% - 30px);
    border-radius: 5px;
    padding: 5px;
    cursor: default;
}

.equip-zones-served {
    min-height: 50px;
    width: 225px;
    position: absolute;
    top: 60px;
    right: 17px;
    border: 2px solid var(--prim);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    z-index: 10;
    background: var(--bg);
}

.served-zone {
    width: 95%;
    height: 35px;
    border-radius: 5px;
    border: 2px solid var(--breezi-blue);
    margin: 7.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.served-zone:hover {
    background: var(--breezi-recessive);
}

.title-served-zone {
    background: var(--prim-transparent);
    border: 2px solid var(--prim);
    width: 100%;
    border-radius: 0px;
    cursor: default;
    margin: 0;
    border-top: none;
}

.title-served-zone:hover {
    background: var(--window);
}

.no-served-zone {
    color: gray;
    border: 2px solid gray;
}

.nav-equip-neighbor {
    width: 30px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}

.nav-equip-node {
    width: 30px;
    height: 200px;
    background: var(--breezi-recessive);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.nav-equip-neighbor:hover .nav-equip-node {
    border: 2px solid var(--white);
    height: 30px;
    border-radius: 10px;
}

.nav-equip-graphic {
    position: absolute;
    height: 60px;
    min-width: 100px;
    border: 2px solid var(--white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nav-equip-graphic img {
    width: 40px;
}

.mob-equip-graphic {
    width: 50px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--breezi-blue-transparent);
    border: 2px solid var(--breezi-blue);
    border-radius: 5px;
    margin-right: 20px;
}
.mob-equip-graphic img, .mob-equip-graphic small {
    transform: rotate(-90deg);
    width: 34px;
}
.mob-equip-graphic img {
    margin-bottom: 50px;
}
.mob-equip-graphic small {
    width: 125px;
    margin-bottom: 50px;
    font-size: 1.1em;
    margin-top: 20px;
}
.r-equip-graphic {
    transform: rotate(180deg);
    margin-right: 0;
    margin-left: 20px;
}

/* .active-airflow img {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: var(--equip);
    margin-right: 4px;
    padding: 5px;
    overflow: visible;
} */

@media (max-width: 1650px) {
    .hvac-elem img {
        height: 150px;
    } 
    .hvac-bank-node {
        width: 90px;
        height: 90px;
        min-width: 90px;
        min-height: 90px;
        max-width: 90px;
        max-height: 90px;
    }
    .hvac-bank-node img {
        height: 40%;
    }
    .hvac-fan-clusters {
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .hvac-fan-cluster {
        width: 100%;
        height: 25%;
    }
}

@media (max-width: 990px) {
    .hvac-elem img {
        height: 100px;
    }   
    .hvac-fan img {
        height: 40px;
    }
    .hvac-bank-node {
        width: 100px;
        height: 100px;
        min-width: 100px;
        min-height: 100px;
        max-width: 100px;
        max-height: 100px;
    }
}

/* @media (min-height:1200px) {
    .equip-panel h6, .equip-panel small {
        font-size: 1.4em;
    }
    .hvac-section {
        max-width: 32%;
    }
    .hvac-sec-main {
        height: 50%;
        margin-top: 30%;
    }
    .sec-title {
        bottom: 1%;
    }
    .hvac-ap {
        width: 180px;
    }
    .ap-equip-rdg {
        width: 120px;
    }
    .ap-equip-rdg h6 {
        font-size: 1.4em;
    }
    .hvac-diff-nodes {
        width: 100%;
    }
    .hvac-diff-nodes .ap-equip-rdg {
        width: 180px;
    }
} */

@media (max-width: 767px) {
    .mob-equip-main {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 90px;
        padding-bottom: 85px;
    }
    .mob-equip-toggle {
        width: 80%;
        height: 80px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: 2px dashed gray;
    }
    .mob-equip-tog {
        width: 40px;
        height: 40px;
        border: 2px solid gray;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        opacity: .5;
    }
    .mob-active-tog {
        width: 60px;
        height: 60px;
        border: 2px solid var(--prim);
        background: var(--prim-transparent);
        opacity: 1;
    }
    .mob-equip-tog img {
        height: 55%;
        max-height: 45px;
    }
    .mob-hvac {
        width: 95%;
        height: calc(80% - 85px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 2.5%;
    }
    .mob-hvac h3 {
        margin: 5p20
    }
    .mob-hvac .hvac-section {
        min-width: 90%;
        max-width: 90%;
        height: 28%;
        margin: 0;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .mob-hvac .hvac-elem {
        min-width: 60px;
        max-width: 80%;
        height: 180px;
        min-height: 180px;
        max-height: 180px;
    }
    .mob-hvac .hvac-elem img {
        min-height: 100%;
        max-height: 100%;
    }

    .mob-sec-aps {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px
    }

    .mob-ap-node {
        height: 80px;
    }
    .mob-ap-equip-rdg .ap-equip-head {
        width: 35%;
        height: 100%;
    }

    .mob-airflow {
        width: 80px;
        height: 80px;
        /* border: 2px solid var(--equip); */
        background: var(--equip);
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 5px;
    }

    .mob-airflow img {
        width: 80%;
    }

    .mob-airflow h4 {
        margin: 8px;
        margin-bottom: -8px;
    }
    .mob-ap-equip-rdg h6 {
        margin: 0;
        font-size: 1.65em;
        margin-left: 10px;
        padding-top: 3px;
    }

    .mob-empty-ap {
        width: 100px;
        height: 100px;
        border: 2px dashed var(--breezi-blue);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mob-empty-ap h5 {
        color: var(--breezi-blue-transparent);
    }
    .mob-diff-empty {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed var(--breezi-blue);
        border-radius: 5px;
        color: var(--breezi-blue-transparent);
        margin-top: 20px;
    }
    .mob-diff-span {
        width: 50%;
        height: 5px;
        flex-direction: row;
        margin-top: 20px;
    }
    .mob-diff-rdgs {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 0px;
    }
    .mob-diff-rdg {
        width: 150px;
    }

    .mob-hvac .hvac-fan-elem img {
        width: 150px;
        height: 150px;
    }
    .expand-blur {
        position: fixed;
        top: 80px;
        bottom: 80px;
        left: 0;
        right: 0;
    }
}

@media (min-height: 400px) and (max-height: 880px) and (min-width: 768px) {
    .hvac-elems {
        max-width: 100px;
    }
    .ap-equip-rdg {
        min-width: 70px;
    }
    .hvac-section {
        margin: 4px;
        max-width: 260px;
    }
}

@media (min-height: 400px) and (max-height: 880px) {
    .airflow-static {
        height: 20px;
        width: auto;
    }
    .hvac-airflow {
        height: 30px;
    }
    .hvac-airflow img {
        max-height: 25px;
    }
    .equip-panel {
        margin-top: 20px;
    }
    .diff-on-off {
        position: absolute;
        bottom: 75px;
        width: 95%;
    }
    .diff-on-off h5 {
        display: none;
    }
    .equip-zones-served {
        top: 32.5px;
        right: 8px;
        border-top-right-radius: 0px;
    }
}

.hvac-airflow {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: calc(50% - 75px);
    top: 0px;
}

.hvac-airflow img {
    height: 40px;
    margin: 10px;
}

.expand-textarea {
    width: 100%;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.expand-textarea textarea {
    height: 95%;
    width: 90%;
    background: none;
    margin: 10px;
    color: var(--white);
    font-weight: lighter;
}

@keyframes len-flash-text {
    12.5% {
        color: var(--crit);
    }
    25% {
        color: gray;
    }
    37.5% {
        color: var(--crit);
    }
    50% {
        color: gray;
    }
    62.5% {
        color: var(--crit);
    }
    75% {
        color: gray;
    }
    87.5% {
        color: var(--crit);
    }
    100% {
        color: gray;
    }
}

@keyframes len-flash-box {
    12.5% {
        margin-right: 2.5px;
        margin-left: 0px;
        background: var(--crit-transparent);
    }
    25% {
        margin-left: 2.5px;
        margin-right: 0px;
        background: none;
    }
    37.5% {
        margin-right: 2.5px;
        margin-left: 0px;
        background: var(--crit-transparent);
    }
    50% {
        margin-left: 2.5px;
        margin-right: 0px;
        background: none;
    }
    62.5% {
        margin-right: 2.5px;
        margin-left: 0px;
        background: var(--crit-transparent);
    }
    75% {
        margin-left: 2.5px;
        margin-right: 0px;
        background: none;
    }
    87.5% {
        margin-right: 2.5px;
        margin-left: 0px;
        background: var(--crit-transparent);
    }
    100% {
        margin-left: 0px;
        margin-right: 0px;
        background: none;
    }
}

@media (min-height: 1200px) {
    .sec-title {
        margin-bottom: 30px;
    }
    .hvac-section {
        max-width: 32%;
        min-width: 300px;
    }
    .hvac-elems {
        width: 0;
    }
    .hvac-sec-main {
        /* min-width: 380px; */
    }
    .hvac-ap {
        min-width: 140px;
    }
    .ap-equip-rdg {
        width: 130px;
        height: 50px;
        font-size: 1.3em;
    }
    .hvac-elem {
        min-width: 120px;
        height: 90%;
    }
    .hvac-elem img {
        min-width: 90px;
        height: auto;
    }
    .lg-fan-elem img {
        min-width: 300px;
    }
    .hvac-diff-nodes .ap-equip-rdg {
        width: 150px;
    }
    .hvac-diff-nodes .ap-equip-rdg img {
        width: 35px;
        height: 35px;
    }
    .hvac-diff-nodes .ap-equip-rdg h6 {
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 17.5px;
    }
}

@media (min-height: 400px) and (max-height:700px) and (min-width: 1100px) {
    .hvac-sections .card, .hvac-sections .card-frame, .hvac-sections .card-face {
        width: 280px;
        height: 390px;
        font-size: .9em;
    }
    .diff-reading-node {
        max-height: 100px;
        margin-top: 5px;
    }
    .dp-card-header {
        max-height: 35px;
        margin-top: 5px;
        font-size: 1.2em;
    }
    .card-settings-icon {
        height: 40px;
        margin-bottom: 10px;
    }
    .card-toggle-node {
        height: 20px;
        width: 20px;
    }
    .card-header-subtitle {
        display: none;
    }
    .card-rdg-content {
        height: 75%;
    }
    .card-toggle {
        max-width: 30%;
        right: 35%;
        bottom: 0px;
    }
    .active-toggle-node {
        width: 30px;
        height: 30px;
    }
    .ap-equip-head img {
        height: 20px;
        width: auto;
        padding: 1%;
        margin-bottom: .5%;
    }
    .section-blur {
        font-size: .9em;
    }
    .expand-header {
        max-height: 30px;
    }
    .expand-header img {
        max-height: 30px;
        max-width: 30px;
        margin-left: 10px;
    }
    .elem-action, .elem-action-head {
        max-height: 30px;
    }
    .expand-close {
        max-width: 12px;
        max-height: 12px;
    }
    .equip-panel {
        width: 88vw;
    }
    .hvac-section {
        height: 90%;
    }
    .hvac-sections .card-rdg-content {
        min-height: 100%;
    }
}

@media (min-height:400px) and (max-height:880px) {
    .hvac-sections .card, .hvac-sections .card-frame, .hvac-sections .card-face {
        width: 320px;
        height: 450px;
        font-size: .9em;
    }
    .expand-header {
        max-height: 8%;
    }
    .expand-header img {
        height: 30px;
        max-width: 30px;
        margin-left: 10px;
    }
    .expand-close {
        max-width: 15px;
        max-height: 15px;
    }
    .conf-maint-msg {
        min-width: 90%;
    }
    .conf-maint-msg h4 {
        font-size: .9em;
        min-width: 100%;
    }
    .elem-action {
        max-height: 40px;
    }
    .elem-action-head {
        max-height: 40px;
    }
    .expand-title {
        max-width: 105%;
        font-size: .9em;
    }
    .ap-equip-head {
        min-height: 20px;
    }
    .hvac-diff-nodes .ap-equip-head img {
        width: 17px;
        height: 17px;
    }
}