.report-main {
    width: 100vw;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.report-content {
    width: 80%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.report-content-pdf {
    overflow-y: scroll;
    justify-content: flex-start;
    width: 1000px;
    height: 1300px;
    background: white;
}

.report-header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 2px solid var(--breezi-blue); */
    position: relative;
}

.report-header-icon {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-blue-transparent);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.report-header-icon img {
    height: 50%;
}

.report-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.report-title h2, .report-title h4 {
    margin: 4px;
    text-align: left;
}

.report-title h2 {
    width: 100%;
    padding-bottom: 8px;
    margin-bottom: 5px;
}

.pdf-zone .report-title h2 {
    border-bottom: 4px solid var(--breezi-blue);
    padding-bottom: 5px;
}

.pdf-zone .report-title {
    width: 89%;
}

.export-btn {
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cancel-transparent);
    cursor: pointer;
    position: relative;
    z-index: 21;
}

.export-btn:hover {
    background: var(--cancel);
}

.export-btn img {
    width: 25px;
}

.export-slide {
    width: 0;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    border: 2px solid var(--breezi-blue);
    border-right: none;
    position: absolute;
    left: 0;
    animation: slideexport .2s linear forwards;
}

.export-slide h3 {
    animation: fadeexport .1s linear forwards;
    animation-delay: .3s;
    opacity: 0;
}

@keyframes slideexport {
    100% {
        width: 180px;
        left: -180px;
    }
}

@keyframes fadeexport {
    100% {
        opacity: 1;
    }
}

.report-row {
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .5%;
}

.report-content-pdf .report-row {
    height: auto;
}

.report-panel {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: var(--window);
    border-radius: 10px;
    border: 1px solid darkgray;
    overflow: hidden;
}

.report-trends {
    width: 74.5%;
}

.report-maint {
    width: 24.25%;
}

.monthly-trend-panel {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-top: 1%;
}

.report-content-pdf .report-trends {
    width: 100%;
}

.report-content-pdf .monthly-trend-panel {
    height: 350px;
}

.monthly-trend-panel h3 {
    margin: 0;
    height: 20px;
    position: absolute;
    /* font-weight: lighter; */
    text-align: left;
    left: 3%;
    border-bottom: 1px solid var(--breezi-blue);
    font-size: 1em;
    color: var(--breezi-blue);
}

.report-top {
    overflow: hidden;
    padding-left: 1%;
}

.report-content-pdf .report-top {
    height: 800px;
}

.report-top .zone-row {
    height: 96%;
    width: 100%;
    margin-left: .5%;
    justify-content: flex-start;
    padding: 0;
    position: relative;
    padding-left: 0;
    margin-left: 0;
    overflow-x: hidden;
}

.report-top .floor-zones {
    height: 100%;
    width: 50%;
    margin: 0;
    padding: 0;
    position: relative;
    align-items: center;
}
.report-panel-pdf .floor-zones {
    flex-direction: column;
}

.report-top .floor-zones-header {
    position: sticky;
    border-radius: 0;
    margin: 0;
    min-width: 60px;
    max-width: 60px;
    cursor: default;
}

.report-top .floor-zones-header h2 {
    rotate: -90deg;
    width: 250px;
    font-size: 1em;
}

.monthly-zone {
    width: 100%;
    height: 100%;
    /* background: var(--bg); */
    border: 2px solid var(--equip);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.monthly-zone .floor-zone-header {
    border-bottom: 2px solid var(--equip);
    height: 30px;
    margin-top: 5px;
}

.monthly-zone .floor-zone-icon {
    border: 2px solid var(--equip);
    background: none;
    border-bottom: none;
    width: 30px;
    height: 27.5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.monthly-zone .floor-zone-icon img {
    width: 20px;
    height: auto;
}

.monthly-zone .floor-zone-title {
    width: 80%;
    margin-left: 6px;
    height: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
}

.monthly-zone .floor-zone-title h3 {
    color: var(--equip);
    font-size: .9em;
    margin: 0;
    padding: 0;
    min-width: 60%;
    max-width: 60%;
}

.monthly-zone .floor-zone-title h5 {
    margin: 0;
    font-size: .8em;
    min-width: 40%;
    max-width: 40%;
    color: var(--breezi-blue);
}

.monthly-enviro .floor-zone-header {
    border-bottom: 2px solid var(--breezi-blue);
}

.monthly-enviro .floor-zone-icon {
    background: var(--breezi-blue);
}

.monthly-enviro .floor-zone-title h3 {
    color: var(--breezi-blue);
    border-bottom: 1px solid var(--breezi-blue);
}

.monthly-enviro .floor-zone-title h5 {
    color: gray;
}

.monthly-equip-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}

.monthly-enviro .monthly-equip-body {
    max-width: 90%;
    flex-direction: column;
    justify-content: center;
}

.monthly-equip-sep {
    height: 70%;
    width: 2px;
    background: gray;
}

.monthly-equip-half {
    width: calc(50% - 1px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.monthly-equip-half h4 {
    font-weight: lighter;
    color: gray;
    border-bottom: 1px solid gray;
    margin: 0;
    margin-top: 4px;
    font-size: .9em;
}

.monthly-equip-half h5 {
    font-size: .75em;
}

.equip-avgs {
    width: 50%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .5%;
}

.equip-hi-lo-avg {
    width: 3px;
    height: 92.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: gray;
    position: relative;
}

.equip-hi-lo {
    width: 30px;
    height: 30px;
    color: darkgray;
    border: 1px solid darkgray;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--bg);
}

.equip-hi-lo h5 {
    margin: 0;
    font-size: .75em;
}

.equip-hi-lo small {
    margin: 0;
    font-size: .3em;
}

.equip-hi {
    border: 2px solid var(--crit);
    color: var(--crit);
}

.equip-lo {
    border: 2px solid var(--sec);
    color: var(--sec);
}

.equip-avg {
    background: var(--bg);
    width: 50px;
    height: 42.5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    border: none;
}
.equip-avg-header {
    width: 100%;
    height: 22.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.equip-avg-header h6 {
    margin: 0;
    margin-left: 5px;
    font-size: .65em;
    width: 50%;
    padding: 0;

}

.equip-avg small {
    margin: 0;
    font-size: .3em;
}

.equip-avg h5 {
    margin: 0;
    flex-direction: column;
}

.equip-avg h5 {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.equip-hi-lo-avg img {
    height: 16px;
    /* right: 27.5px; */
    /* top: 2.5px; */
}

.equip-avg-dap {
    width: 100px;
    height: 32.5px;
    margin-top: 8px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.equip-avg-dap img {
    height: calc(100% - 10px);
    width: auto;
    padding: 5px;
    background: var(--bg);
}

.equip-avg-dap h5 {
    margin: 0;
    width: calc(100% - 40px);
}

.equip-delta {
    width: 100%;
    height: 15%;
    border-top: 2px solid var(--breezi-blue);
    background: var(--breezi-recessive);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* position: absolute;
    bottom: 0; */
}

.equip-delta-icon {
    width: 55px;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-right: 2px solid var(--breezi-blue);
    background: var(--breezi-blue-transparent);
}

.equip-delta-icon img {
    height: 25px;
    margin-bottom: 2px;
}

.equip-delta-icon h1 {
    font-weight: lighter;
    height: 100%;
    margin: 0;
}

.equip-delta-span {
    width: calc(100% - 55px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.equip-delta-bar {
    width: 80%;
    height: 2px;
    background: darkgray;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.equip-delta-node {
    position: absolute;
    width: 35px;
    height: 35px;
    border: 1px solid var(--breezi-blue);
    background: var(--breezi-recessive);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    overflow: hidden;
}

.max-node h5 {
    background: var(--crit-glass);
}

.equip-delta-node h5 {
    width: 100%;
    height: 100%;
    line-height: .8em;
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
}

.equip-delta-node span {
    font-size: .5em;
}


.monthly-enviro {
    border: 2px solid var(--breezi-blue);
}

.hi-lo-stretch {
    width: 1px;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    margin-bottom: 40px;
    padding: 0;
    position: relative;
}

.stretcher {
    height: 2px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.stretcher-hi {
    color: var(--crit);
}

.stretcher h5 { 
    margin: 0;
    font-size: .65em;
    background: var(--bg); 
    padding: 2px;
}

.stretcher-lo {
    bottom: 15px;
    /* background: var(--sec); */
    color: var(--sec);
}

.report-main .highcharts-tooltip {
    max-width: 150px;
}

.monthly-enviro-gauge {
    width: 100%;
    height: 18%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1%;
    position: relative;
}

.enviro-span {
    max-width: 80%;
    min-width: 80%;
    height: 2px;
    background: gray;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.enviro-hi-lo {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg);
    border-radius: 100%;
    position: absolute;
    font-size: .75em;
}
.enviro-hi-lo h2, .enviro-hi-lo small {
    margin-top: -2px;
    margin-bottom: -2px;
}
.enviro-hi-lo h2 {
    font-size: 1.2em;
}

.enviro-lo {
    border: 2px solid var(--sec);
    color: var(--sec);
    left: 0;
}

.enviro-hi {
    border: 2px solid var(--crit);
    color: var(--crit);
    right: 0;
}

.enviro-avg {
    width: 65px;
    height: 35px;
    background: var(--bg);
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    border-radius: 5px;
}

.enviro-avg h2 {
    width: 55%;
    font-size: .8em;
}

.prim-enviro-avg {
    border: 2px solid var(--prim);
    color: var(--prim);
}

.cancel-enviro-avg {
    border: 2px solid var(--cancel);
    color: var(--cancel);
}

.crit-enviro-avg {
    border: 2px solid var(--crit);
    color: var(--crit);
}

.enviro-avg-head {
    width: calc(50% - 2px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 50%;
    bottom: 0;
}

.enviro-avg-head img {
    width: 15px;
}

.prim-enviro-avg .enviro-avg-head {
    background: var(--prim-transparent);
    border-right: 2px solid var(--prim)
}
.cancel-enviro-avg .enviro-avg-head {
    background: var(--cancel);
    border-right: 2px solid var(--cancel)
}
.crit-enviro-avg .enviro-avg-head {
    background: var(--crit);
    border-right: 2px solid var(--crit)
}

.monthly-maint {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.monthly-maint-sep {
    width: 65%;
    height: 2px;
    background: var(--white);
}

.monthly-maint-panel {
    width: 95%;
    background: var(--bg);
    border: 2px solid var(--white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    padding-top: 25px;
    height: 37.5%;
}

.monthly-maint-panel h3 {
    margin: 0;
    top: 4px;
    text-align: left;
    font-size: .9em;
    margin-left: 5px;
    font-weight: 400;
    background: var(--bg);
    width: 95%;
    position: absolute;
}

.monthly-maint img {
    max-height: 20px;
}

/* .maint-performed {
    height: 37.5%;
}

.maint-required {
    height: 37.5%;
} */

.monthly-evt {
    width: 90%;
    min-height: 40px;
    border: 1px solid var(--white);
    margin: .5%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.monthly-evt img {
    height: 60%;
    margin-left: 5px;
}

.monthly-evt-title {
    height: 100%;
    width: 35%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.monthly-evt-title h4, .monthly-evt-title small {
    position: relative;
    margin: 0;
    height: 45%;
    text-align: left;
    font-weight: lighter;
    overflow: hidden;
    text-overflow: ellipsis;
}

.monthly-evt-title h4 {
    color: var(--equip);
    margin-top: 3px;
    font-size: .85em;
}

.monthly-evt-title h5 {
    width: 90%;
    text-align: left;
    font-size: .7em;
    font-weight: lighter;
    margin: 0;
}

.monthly-evt-sep {
    width: 2px;
    height: 70%;
    background: var(--white);
}

.monthly-evt-initials {
    width: 32.5px;
    height: 32.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-blue);
    border-radius: 100%;
    margin-right: 10px;
}

.monthly-evt-initials h3 {
    margin: 0;
    position: relative;
    background: none;
    width: 100%;
    margin-bottom: 20%;
    text-align: center;
}

.evt-assign {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: var(--brick-util); */
    background: gray;
    margin-right: 10px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid var(--white);
    cursor: not-allowed;
}

.evt-assign h6 {
    margin: 0;
    font-size: .6em;
}

.report-month-outer {
    height: 60px;
    display: flex;
    /* align-self: flex-end;
    justify-content: flex-end; */
    justify-content: center;
    align-items: center;
    position: relative;
    width: 200px;
    z-index: 10;
    margin-right: 10px;
}

.report-header-month {
    position: absolute;
    right: 0;
    font-family: Arial, Helvetica, sans-serif sans-serif;
    font-weight: lighter;
    font-size: 1.3em;
    cursor: pointer;
}

.main-report-header .report-header-month {
    margin: 0;
}

.month-dd {
    width: 100%;
    min-height: 50px;
    border: 2px solid var(--breezi-blue);
    border-radius: 5px;
    position: absolute;
    top: 0;
    background: var(--bg);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 2%;

}

.report-month {
    width: 95%;
    height: 50px;
    border: 2px solid var(--breezi-blue);
    margin: 3px 0 3px 0;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.report-month:hover {
    background: var(--breezi-blue-transparent);
}

.report-header-month:hover {
    color: var(--breezi-blue);
}

.report-zone-overlay {
    position: absolute;
    width: 97.5%;
    height: 97.5%;
}

.monthly-active, .monthly-inactive {
    max-height: 95%;
    height: 340px;
    min-width: 340px;
    max-width: 340px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.monthly-active {
    /* background: #24382a; */
    animation: pulsebg 3.5s infinite;
    background: var(--bg);
}

.report-panel-pdf {
    flex-direction: column;
}

.monthly-maint-detail {
    width: 45%;
    height: 800px;
    background: var(--window);
    margin-left: 10px;
    border: 1px solid var(--white);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

@keyframes pulsebg {
    25% {
        background: var(--breezi-blue-glass);
        /* background: linear-gradient(to top, var(--prim-transparent) 8%, var(--bg)); */
    }
    50% {
        background: var(--bg);
        /* background: linear-gradient(to top, var(--prim-transparent) 0%, var(--bg)); */
    }
    75% {
        background: var(--breezi-blue-glass);
        /* background: linear-gradient(to top, var(--prim-transparent) 8%, var(--bg)); */
    }
    100% {
        background: var(--bg);
        /* background: linear-gradient(to top, var(--prim-transparent) 1%, var(--bg)); */
    }
}

.monthly-inactive {
    background: var(--bg);
}

@media (min-height:1200px) {
    /* .monthly-zone {
        height: 450px;
        width: 450px;
    } */
    .monthly-active, .monthly-inactive {
        max-height: 95%;
        height: 550px;
        min-width: 550px;
        max-width: 550px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 1.2em;
        margin-left: 10px;
    }
    .equip-hi-lo {
        width: 50px;
        height: 50px;
    }
    .monthly-zone h5 {
        font-size: 1em;
    }
    .equip-avg {
        width: 70px;
        height: 55px;
    }
    .equip-avg-dap {
        width: 150px;
        height: 60px;
    }
    .equip-avg-dap img {
        height: 40px;
    }
    .equip-delta-node {
        width: 45px;
        height: 45px;
    }
    .monthly-evt {
        min-height: 50px;
        max-height: 50px;
    }
    .monthly-maint img {
        min-height: 40px;
        max-height: 40px;
    }
    .monthly-zone .floor-zone-header {
        height: 45px;
        margin-top: 10px;
    }
    .monthly-zone .floor-zone-icon {
        height: 100%;
        width: 45px;
    }
    .monthly-zone .floor-zone-icon img {
        width: 80%;
        height: auto;
    }
    .equip-avg-dap {
        width: 180px;
    }
    .equip-avg-dap img {
        padding: 8px;
    }
    .enviro-hi-lo {
        width: 50px;
        height: 50px;
    }
    .enviro-avg {
        width: 90px;
        height: 45px;
    }
    .enviro-avg img {
        height: 37px;
        width: auto;
    }
}

@media (min-height:400px) and (max-height:880px) {
    .report-main {
        height: calc(100vh - 20px);
        margin-top: 42px;
    }
    .report-content {
        height: 95vh;
        margin: 0;
        width: 88vw;
    }
    .report-header {
        height: 40px;
        font-size: .7em;
    } 
    .report-header-icon {
        height: 40px;
    }
    .report-title {
        padding-bottom: 4px;
    }
    .report-title h2 {
        margin: 0;
        margin-left: 2.5px;
    }
    .report-top {
        justify-content: flex-start;
    }
    .report-row {
        min-height: 43%;
        max-height: 43.5%;
        position: relative;
    }
    .report-row .floor-zones-header {
        min-width: 40px;
        max-width: 40px;
    }
    .report-row .floor-zones-header img {
        width: 30px;
    }
    .report-row .floor-zones {
        width: 96%;
        justify-content: flex-start;
        /* overflow-x: scroll; */
        margin-left: .5%;
    }
    .report-row .floor-equips .zone-row {
        border: 1px solid var(--equip);
        overflow-x: scroll;
    }
    .enviro-row {
        border: 1px solid var(--breezi-blue);
    }
    .report-row .zone-row {
        max-width: calc(100% - 2px);
        border: 1px solid var(--breezi-blue);
        margin-left: 0;
        justify-content: flex-start;
        overflow-x: hidden;
        position: relative;
    }
    /* .report-row .monthly-zone {
        min-width: 270px;
        max-width: 270px;
        min-height: 270px;
        max-height: 270px;
        border: 1px solid var(--breezi-blue);
        margin-left: 20px;
        margin-right: 20px;
        margin: 4px;
    } */
    .monthly-active, .monthly-inactive {
        max-height: 95%;
        height: 270px;
        min-width: 270px;
        max-width: 270px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }
    .monthly-trend-panel h3 {
        top: -5px;
        font-size: .9em;
        height: 15px;
        border-bottom: 1px solid var(--prim);
        left: 7.25%;
    }
    .report-row .monthly-zone .floor-zones-header {
        margin: 0;
    }
    .report-row .monthly-equip {
        border: 1px solid var(--equip);
        font-size: .9em;
    }
    .report-tog-outer {
        width: 8%;
        height: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1%;
        border-left: 2px solid gray;
        position: absolute;
        right: 0;
    }
    .report-tog {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;

    }
    .report-tog img {
        width: 82%;
    }
    .report-tog-equip {
        background: var(--breezi-blue);
    }
    .report-tog-envZone {
        background: var(--equip);
    }

    .equip-avg {
        height: 25px;
        width: 45px;
    }
    .enviro-avg {
        width: 50px;
        height: 30px;
    }
    .enviro-avg-head {
        width: 100%;
        flex-direction: column;
    }
    .prim-enviro-avg .enviro-avg-head {
        border: none;
    }
    .cancel-enviro-avg .enviro-avg-head {
        border: none;
    }
    .crit-enviro-avg .enviro-avg-head {
        border: none;
    }

    .enviro-avg-head {
        flex-direction: row;
    }
    .enviro-avg h2 {
        width: 60%;
    }
    .enviro-hi-lo {
        width: 30px;
        height: 30px;
        font-size: .55em;
    }

    /* .report-tog {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .report-tog img {
        width: 30px;
    }
    .report-tog-equip {
        right: 2px;
        background: var(--breezi-blue);
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .report-tog-envZone {
        left: 2px;
        background: var(--equip);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    } */

    .equip-delta-node {
        width: 30px;
        height: 30px;
    }
}

.report-pdf-main {
    align-items: flex-start;
    overflow-y: hidden;
}

.report-pdf-main .report-header {
    height: 100px;
    width: 99%;
    /* border-bottom: 5px solid var(--breezi-blue); */
    border-bottom: none;
}

.report-pdf-content {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow-y: auto;
}

.report-pdf-content:nth-child(2) {
    font-family: Garamond;
}

.report-pdf-main .report-header-icon {
    background: var(--report-l-blue);
    border-radius: 0;
    position: relative;
    flex-direction: column;
    border-radius: 5px;
}
.report-pdf-main .report-header-icon img {
    top: 4px;
    left: 4px;
    width: 70%;
    height: auto;
}

.pdf-header-sec-icon {
    align-self: flex-end;
}

.report-header h2, .report-header h4 {
    color: var(--window);
}

.main-report-header {
    justify-content: space-around;
    width: 100%;
    align-self: center;
    margin: 0;
}

.main-report-header .report-title {
    width: 90%;
}

.main-report-header h2, .main-report-header h4, .main-report-header span {
    font-weight: normal;
    margin-bottom: 0;
}

.main-report-header h4 {
    color: gray;
    margin-top: 0;
}

.main-report-header h2 {
    color: var(--white);
}


.pdf-zone {
    min-height: 1300px;
    max-height: 1300px;
    width: 1000px;
    margin: 10px 0 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 5%;
    background: white;
    padding: 50px 50px 50px 50px;
}

.pdf-zone-container {
    width: 48%;
    height: 310px;
    color: var(--bg);
    border: 3px solid var(--breezi-recessive);
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 0 10px 0;
}

.pdf-equip {
    /* border: 3px solid var(--equip); */
}

.pdf-nameplate {
    margin-left: 1%;
}

.pdf-zone-container header, .pdf-header {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: var(--breezi-recessive);
}

.pdf-zone-container header h2 {
    font-size: 1em;
    color: white;
    margin-left: 10px;
}

.pdf-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-blue);
}

.pdf-equip .pdf-icon {
    background: var(--equip);
}

.pdf-icon img {
    width: 30px;
}

.pdf-title {
    width: calc(100% - 50px);
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 10px;
}

.pdf-title h3 {
    color: var(--breezi-blue);
}

.pdf-equip .pdf-title h3 {
    color: var(--equip);
}

.pdf-title h5 {
    color: white;
    font-weight: lighter;
    font-size: 1em;
}

.pdf-title-icon {
    height: 40px;
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* background: var(--breezi-recessive); */
    margin-left: 4px;
    border-radius: 4px;
    border: 2px solid var(--breezi-recessive);
    margin-top: 10px;
    margin-bottom: -5px;
}

.pdf-title-icon h4 {
    color: var(--white);
    margin-left: 15px;
}

.pdf-title-icon .report-header-icon {
    width: 40px;
    height: 40px;
}

.pdf-title-icon .report-header-icon img {
    width: 22.5px;
}

.pdf-title-icon h4 {
    color: var(--breezi-recssive);
}

.pdf-title-icon .report-header-icon small {
    font-size: .6em;
    margin-top: 3px;
}

.pdf-zone-body {
    height: calc(100% - 65px);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.pdf-equip-half {
    width: calc(50% - 1px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.pdf-equip-half h3 {
    color: gray;
    margin: 4px;
    font-size: 1em;
}

.pdf-hi-los {
    margin-top: 0px;
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pdf-equip-range {
    width: 27.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 15px 0 15px;
}

.pdf-equip-span {
    width: 2px;
    height: 100%;
    background: var(--breezi-recessive);
}

.pdf-range-icon {
    width: 62.5px;
    height: 35px;
    border: 2px solid var(--breezi-recessive);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-recessive);
    position: absolute;
    top: 0;
}

.pdf-range-icon img {
    height: 75%;
}

.pdf-hi-lo-avg {
    width: 65px;
    position: absolute;
    font-size: 1.3em;
}

.pdf-hi {
    top: 52.5px;
    color: var(--crit);
    border-bottom: 3px solid var(--crit);
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-hi-lo-avg h5 {
    font-weight: normal;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.pdf-avg {
    top: 60%;
    color: green;
    border-bottom: 3px solid green;
}

.pdf-lo {
    top: calc(100% - 24px);
    color: var(--sec);
    border-bottom: 3px solid var(--sec);
}

.pdf-hla-label {
    position: absolute;
    height: calc(100% - 40px);
    top: 92px;
}

.pdf-hla-label h6 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: .9em;
}

.hla-hi {
    color: var(--crit);
}

.hla-avg {
    margin-top: -10px;
    /* margin-top: 25px; */
    color: green;
}

.hla-lo {
    margin-top: -10px;
    /* margin-top: 31px; */
    color: var(--sec);
}

.pdf-days {
    width: 85%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.prim-pdf-days {
    border: 3px solid green;
    color: green;
}
.cancel-pdf-days {
    border: 3px solid var(--cancel);
    color: var(--cancel);
}
.crit-pdf-days {
    border: 3px solid var(--crit);
    color: var(--crit);
}

.pdf-days-head {
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pdf-days-head img {
    height: 70%;
    margin-right: 20px;
}

.pdf-days-head h5 {
    color: white;
}

.prim-pdf-days .pdf-days-head {
    background: green;
}
.cancel-pdf-days .pdf-days-head {
    background: var(--cancel);
}
.crit-pdf-days .pdf-days-head {
    background: var(--crit);
}

.pdf-days-body {
    width: 25%;
    height: 100%;
}

.pdf-days h4, .pdf-days small {
    margin: 0;
    height: 40%;
    margin-top: 3px;
}

.pdf-delta {
    width: 100%;
    height: 40px;
    background: var(--breezi-recessive);
    border-top: 2px solid var(--breezi-recessive);
    margin-top: -5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pdf-delta-head {
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 3px solid gray;
}

.pdf-delta-head h2 {
    color: white;
}

.pdf-delta-head img {
    height: 20px;
    margin-left: 10px;
    margin-bottom: 1px;
}

.pdf-delta-range {
    width: calc(100% - 120px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 3px;
    position: relative;
    margin-left: 30px;
}

.pdf-delta-sep {
    width: 100%;
    height: 3px;
    background: white;
}

.pdf-delta-node {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    position: absolute;
}

.pdf-delta-node h4, .pdf-delta-node small {
    margin: 0;
}

.pdf-delta-node small {
    font-size: .5em;
}

.pdf-delta-avg {
    left: calc(50% - 15px);
}

.pdf-delta-hi {
    right: 0;
}

.pdf-enviro-container {
    width: 98%;
    height: 36%;
}

/* .pdf-enviro-half {
    width: auto;
} */

.pdf-enviro-half h3 {
    margin-top: -6px;
}

.pdf-enviro-body {
    align-items: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
}

.pdf-enviro-ranges {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    overflow: visible;
    margin-top: -5px;
}

.pdf-enviro-range {
    width: 85%;
    height: 130px;
    /* border: 2px solid var(--breezi-recessive); */
    overflow: hidden;
    margin: 1px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    /* margin: 3px; */
    /* margin-bottom: 8px; */
    /* display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative; */
}

.pdf-enviro-icon {
    min-width: 75px;
    max-width: 75px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--breezi-recessive);
    border-right: 3px solid white;
}

.pdf-enviro-icon img {
    height: 40px;
}

.pdf-enviro-hla {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.pdf-enviro-lo {
    border: 4px solid var(--sec);
    margin-left: 10px;
}

.pdf-enviro-runs {
    /* width: calc(100% - 55px);
    height: 98%; */
    width: calc(100% - 85px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow: visible; 
}

.pdf-enviro-run {
    height: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: visible;
    margin: 2px 0 2px 0;
    position: relative;
    /* margin: 0 17px 0 17px; */
}

.run-head {
    bottom: 0px;
    height: 32.5px;
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0px;
    background: white;
    margin-right: 10px;
    /* border-radius: 100%; */
    /* border-radius: 100%; */
    /* width: 15px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    position: absolute;
    right: -10px;
    border-right: 4px solid white; */
}

.pdf-enviro-rdg small {
    /* border-top: 2px solid white; */
    padding-top: 2px;
    font-size: .7em;
}

.run-cap {
    width: 5px;
    height: 30px;
    background: white;
}

.run-hi {
    background: var(--crit);
}
.run-hi .run-head {
    background: var(--crit);
}

.run-avg {
    background: green;
}
.run-avg .run-head {
    background: green;
}

.run-lo {
    background: var(--sec);
}
.run-lo .run-head {
    background: var(--sec);
}

.run-body {
    width: 30%;
}

.run-head h2 {
    /* color: white; */
    text-decoration: none;
    margin: 0;
    font-size: .75em;
    font-weight: 700;
}

.run-avg .run-body {
    margin-right: 20px;
}

.run-lo .run-body {
    margin-right: 35px;
}

.pdf-enviro-icon small {
    border: none;
    margin: 0;
    margin-top: 3px;
    font-size: .75em;
}



/* .pdf-enviro-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-recessive);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.pdf-enviro-icon img {
    height: 60%;
}

.pdf-enviro-span {
    width: calc(100% - 40px);
    height: 2px;
    background: var(--breezi-recessive);
    align-self: flex-end;
}

.pdf-enviro-hi-lo {
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.2em;
    width: 27%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-enviro-hi-lo h5 {
    margin: 0;
    line-height: 10px;
}

.pdf-enviro-lo {
    left: 70px;
    color: var(--sec);
    border-left: 2px solid var(--sec);
}

.pdf-enviro-avg {
    left: 50%;
    color: green;
    border-left: 2px solid green;
}

.pdf-enviro-hi {
    left: 77%;
    color: var(--crit);
    border-left: 2px solid var(--crit);
}

.pdf-enviro-hla {
    width: 90%;
    height: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pdf-enviro-hla h5 {
    padding: 1%;
    color: white;
    border-radius: 3px;
} */

.enviro-hla-lo {
    background: var(--sec);
    margin-left: calc(70px + 7%);
}
.enviro-hla-avg {
    background: green;
    margin-left: 14%;
}
.enviro-hla-hi {
    background: var(--crit);
    margin-left: 13.5%;
}

.pdf-maint {
    width: 98%;
    height: 185px;
    border: 3px solid var(--breezi-recessive);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.pdf-trend {
    width: 98%;
    border: 3px solid var(--breezi-recessive);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin: 0;
    padding-top: 20px;
}

.pdf-maint-evt {
    width: 97.5%;
    height: 35px;
    border: 2px solid var(--breezi-recessive);
    margin: .5%;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pdf-maint-header {
    width: 45px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-recessive);
}

.pdf-maint-header img {
    height: 70%;
}

.pdf-maint-body {
    width: 88%;
    height: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-start;
    align-items: center;
}

.pdf-maint-body h3 {
    width: 30%;
    height: 100%;
    color: var(--breezi-recessive);
    font-size: .9em;
    text-align: left;
    background: lightblue;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2%;
    border-right: 2px solid var(--breezi-recessive);
}

.pdf-maint-body p {
    color: var(--bg);
    height: 100%;
    margin: 5px;
    margin-top: 0px;
    text-align: left;
    width: 70%;
    font-size: .8em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pdf-maint-index {
    width: 37.5px;
    height: 35px;
    color: var(--breezi-recessive);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:.85em;
    /* background: var(--bg); */
    /* background: lightblue;
    background: white; */
    background: var(--breezi-recessive);
}

.pdf-maint-index-container {
    width: 27.5px;
    height: 27.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: var(--bg);
}

.pdf-maint-index h1 {
    color: white;
    font-size: 1.5em;
    font-weight: lighter;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 17px;
}

.pdf-zone .highcharts-grid-line {
    background: var(--bg);
    border: 1px solid var(--bg);
    color: var(--bg);
}

.pdf-no-maint {
    width: 97.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pdf-no-maint-icon {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: gray;
    margin-bottom: 8px;
}

.pdf-no-maint-icon img {
    width: 50%;
}

.pdf-no-maint h2 {
    color: gray;
    margin: 3px;
    font-size: .9em;
}

.pdf-equip-zone .pdf-maint, .pdf-equip-zone .pdf-trend {
    /* border: 3px solid var(--equip); */
}

.pdf-enviro-zone .pdf-maint, .pdf-enviro-zone .pdf-trend {
    /* border: 3px solid var(--breezi-blue); */
}

.pdf-trend .pdf-header {
    position: absolute;
    top: 0;
}

.pdf-nameplate-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 20px;
}

.pdf-np-node {
    width: 90%;
    height: 35px;
    margin: 3px 0 3px 0;
    border-bottom: 4px solid var(--breezi-recessive);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pdf-np-head {
    width: 35px;
    height: 35px;
    background: var(--breezi-recessive);
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-np-head img {
    height: 70%;
}

.pdf-np-node h5 {
    width: calc(50% - 20px);
    text-align: left;
    margin-left: 2.5%;
}

.pdf-np-title {
    border-right: 4px solid var(--breezi-recessive);
}

.pdf-month {
    width: 230px;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    border-left: 4px solid var(--breezi-blue);
    border-radius: 0;
    margin-bottom: 21px;
    padding-left: 10px;
    background: white;
}

.pdf-month h2, .pdf-month small {
    margin: 0;
    color: var(--breezi-blue);
}

.pdf-month small {
    color: var(--breezi-recessive);
}

.pdf-zone .report-title {
    width: 90;
}

.pdf-notes {
    width: 98%;
    height: 180px;
    margin-top: 0;
}

.conf-pdf-export {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg);
    top: 0;
    cursor: pointer;
    z-index: 11;
    position: sticky;
    border-bottom: 3px solid var(--bg);
    animation: fade-conf .75s forwards;
    animation-delay: .75s;
    opacity: 0;
}

@keyframes fade-conf {
    100% {
        opacity: 1;
    }
}

.conf-pdf-export-btn {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulseready 2s linear infinite;
}

.conf-pdf-export-btn:hover {
    animation: none;
    background-color: var(--prim);
}

@keyframes pulseready {
    0% {
        background-color: var(--prim-glass);
    }
    20% {
        background-color: var(--prim-transparent);
    }
    50% {
        background-color: var(--prim);
    }
    80% {
        background-color: var(--prim-transparent);
    }
    100% {
        background-color: var(--prim-glass);
    }
}

.pdf-trend {
    height: 30%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    /* background: blue; */
}
.pdf-trend header {
    margin-bottom: -50px;
}

.pdf-zone .dp-panel {
    background: white;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0;
}

.conf-pdf-export-btn img {
    height: 50px;
}

.conf-pdf-export-btn h4 {
    margin: 0px;
}

.pdf-enviro-zone .pdf-trend {
    height: 34.5%;
    margin-bottom: 10px;
}

.pdf-split-zone .report-header {
    margin-top: 20px;
}

.pdf-enviro-sep {
    height: 3px;
    background: darkgray;
    width: 80%;
    margin: 3%;
    margin-top: 4%;
}

.pdf-title-icon-free {
    width: 99%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.export-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

@media print {
    @page {
        size: 8.5in 11in;
    }
    .report-header {
        margin-bottom: 30px;
    }
    .pdf-enviro-icon {
        height: 135px;
    }
    .pdf-title-icon {
        overflow: hidden;
        margin-bottom: -25px;
        margin-left: 5px;
    }
    .pdf-title-icon-free {
        margin-bottom: -5px;
    }
    .pdf-title-icon-free .pdf-title-icon {
        margin-bottom: 5px;
        margin-left: 0;
    }
    .pdf-title-icon .report-header-icon {
        background-color: var(--breezi-recessive);
        flex-direction: column;
        color: white;
        margin: 0;
        padding: 0;
    }
    .pdf-split-zone {
        height: 90%;
    }
    .pdf-enviro-container {
        height: auto;
        padding-bottom: 3%;
    }

    .pdf-enviro-range {
        height: 135px;
    }
    .pdf-days-head {
        padding-top: 1px;
        padding-right: 2px;
    }
    .pdf-days-body {
        text-align: center;
    }
    .pdf-hi-lo-avg h5 {
        text-align: center;
    }
}

.inner-report-row {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
}