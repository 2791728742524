.loading-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999; /* Add a high z-index value to make it appear above other elements */
    background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background color for overlay effect */
    display: flex;
    justify-content: center;
    align-items: center;
  }

.loading-main .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.letter-node {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
}

@keyframes orbit-left {
    0% {
        transform: rotateZ(0deg);
    }
    25% {
        transform: rotateZ(0deg);
    }
    50% {
        transform: rotateZ(-180deg);
    }
    80% {
        transform: rotateZ(-180deg);
    }
    100% {
        transform: rotateZ(-360deg);
    }
}

@keyframes orbit-right {
    0% {
        transform: rotateZ(0deg);
    }
    25% {
        transform: rotateZ(0deg);
    }
    50% {
        transform: rotateZ(180deg);
    }
    80% {
        transform: rotateZ(180deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

.loading-letter {
    width: 15px;
    height: 15px;
    background: var(--window);
    position: absolute;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--breezi-blue);
}

.loading-letter h5 {
    font-size: .75em;
    color: white;
}

.loading-let {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 100%;
    animation: text-pulsate 5.5s linear infinite;
    opacity: 0;
}

@keyframes text-pulsate {
    5% {
        opacity: 0;
    }
    30% {
        opacity: 1;
        background: var(--breezi-blue);
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.alt-let {
    width: 15px;
    height: 15px;
    opacity: 0;
    animation: text-pulsate-alt 5.5s linear infinite;
    transform: rotateZ(180deg);
    opacity: 0;
    border-radius: 100%;
}

@keyframes text-pulsate-alt {
    10% {
        opacity: 0;
    }
    55% {
        opacity: 0;
    }
    80% {
        opacity: 1;
        background: var(--breezi-blue);
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}