 .section-blur {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);
}
.radial {
    width: 185px;
    height: 185px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
}
.radial-node {
    width: 65px;
    height: 65px;
    margin: 4px;
    position: relative;
}
.radial-btn {
    width: 65px;
    height: 65px;
    border: 2px solid var(--breezi-blue);
    background: var(--window);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: absolute;
    overflow: visible;
}
.radial-btn:hover {
    /* width: 75px;
    height: 75px; */
    background: rgba(111, 111, 111, 0.9);
}
.radial-btn img {
    height: 28px;
    margin: 2px;
}
.radial-btn small {
    font-size: .7em;
}
.radial-ap {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--window);
    position: absolute;
    border: 2px solid var(--white);
    overflow: hidden;
}
.radial-ap img {
    width: 48px;
    border-radius: 100%;
}
.radial h6 {
    font-size: 1em;
    position: absolute;
    top: -37.5px;
    color: var(--breezi-blue);
}

.expand-blur {
    z-index: 5; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.9);
}

.expand-header {
    width: 85%;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid var(--gray);
    padding: 2%;
    margin-top: 10px;
}

.expand-header img {
    width: 50px;
}

.expand-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.expand-header h5, .expand-header small {
    text-align: right;
    margin: 2px;
    color: gray;
    font-size: .75em;
}

.expand-header h5 {
    font-size: 1em;
    color: var(--breezi-blue);
}

.expand-blur footer {
    position: absolute;
    bottom: 0;
    color: var(--white);
    opacity: .8;
    font-size: 1.1em;
    border-top: 1px solid var(--white);
    height: 30px;
    font-weight: lighter;
}

.expand-blur h6 {
    margin: 5px;
}

.expand-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.expand-sub {
    margin: 5px;
    margin-top: 10px;
    width: 85%;
    text-align: left;
    font-size: 1em;
    font-weight: lighter;
}

.action-sub {
    color: var(--sec);
    border-bottom: 2px solid var(--sec);
}

.info-sub {
    color: var(--cancel);
    border-bottom: 2px solid var(--cancel);
}

.elem-action {
    width: 75%;
    max-width: 250px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 4px;
    background: var(--sec-transparent);
    border: 2px solid var(--sec);
    border-radius: 5px;
    cursor: pointer;
    padding-right: 8px;
}

.elem-action h5 {
    width: calc(100% - 50px);
    text-align: center;
}

.elem-info {
    background: var(--cancel-transparent);
    border: 2px solid var(--cancel);
}

.elem-submit {
    background: var(--prim-transparent);
    border: 2px solid var(--prim);
}

.inactive-action {
    background: rgba(111, 111, 111, 0.5);
    border: 2px solid var(--gray);
    cursor: not-allowed;
}

.elem-action:hover {
    background: var(--sec);
}

.elem-info:hover {
    background: var(--cancel);
}

.inactive-action:hover {
    background: rgba(111, 111, 111, 0.5);
}

.elem-action-head {
    height: 50px;
    width: 50px;
    background: var(--sec);
    border: 1px solid var(--sec);
    display: flex;
    justify-content: center;
    align-items: center;
}

.inactive-action .elem-action-head {
    background: gray;
    border: none;
}

.elem-action img {
    height: 30px;
}

.elem-info .elem-action-head {
    background: var(--cancel);
    border: none;
}

.expand-dims {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expand-input {
    width: 70%;
    min-width: 220px;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 8px;
    color: gray;
    font-size: .9em;
}

.expand-input input {
    font-size: 2em;
    border: 2px solid var(--white);
    background: none;
    border-radius: 5px;
    color: var(--white);
    margin-bottom: 5px;
    width: 100%;
}

.expand-dims .expand-input {
    display: flex;
    min-width: 50px;
    justify-content: center;
    align-items: center;
    width: 40%;

}

.expand-dims .expand-input input {
    width: 100px;
}

.expand-input input[type="number"]::-webkit-inner-spin-button,
.expand-input input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
}

.elem-submit:hover {
    background: var(--prim);
}

.elem-submit .elem-action-head {
    background: var(--prim);
    border: none;
}

.del-action {
    background: var(--crit-transparent);
    border: 2px solid var(--crit);
}

.del-action:hover {
    background: var(--crit);
}

.del-action .elem-action-head {
    background: var(--crit);
    border: none;
}




/* 

.expand-blur h2 {
    color: var(--breezi-blue);
    font-weight: lighter;
    margin: 0;
    max-width: 85%;
    padding: 1%;
    margin: 1%;
    font-size: 1.2em;
}

.expand-blur .hvac-elem {
    padding: 5%;
}

.expand-blur img {
    width: 60%;
    height: auto;
}

.expand-blur .settings-card-btn {
    height: 30px;
    max-width: 200px;
} */






/* .S-radial {
    left: -72.5px;
}
@keyframes radial-slide-S {
    100% {
        left: 2px;
    }
}
.W-radial {
    left: -72.5px;
    top: -72.5px;
}
@keyframes radial-slide-W {
    0% {
        transform: translateX(0) translateY(0);
    }
    50% {
        transform: translateX(72.5px) translateY(0);
    }
    75% {
        transform: translateX(72.5px) translateY(0);
    }
    100% {
        transform: translateX(72.5px) translateY(72.5px);
    }
}

.W-radial {
    left: -72.5px;
    top: -72.5px;
} */
@keyframes radial-slide-W {
    0% {
        transform: translateX(0) translateY(0);
    }
    20% {
        transform: translateX(72.5px);
    }
    40% {
        transform: translateX(72.5px);
    }
    60% {
        transform: translateX(72.5px) translateY(72.5px);
    }
    80% {
        transform: translateX(72.5px) translateY(72.5px);
    }
    100% {
        transform: translateX(0) translateY(0);
    }
    /* 0% {
        transform: translateX(0) translateY(0);
    }
    25% {
        transform: translateX(72.5px) translateY(0);
    }
    50% {
        transform: translateX(0) translateY(72.5px);
    }
    75% {
        transform: translateX(-72.5px) translateY(0);
    } */
}