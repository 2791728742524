.lg-selector {
    width: 100%;
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    padding-top: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: -2%;
}

.lg-selector label {
    width: 200px;
    height: 40px;
    margin: 0;
    margin-left: 5px;
}

.selector-node {
    width: 212.5px;
    height: 40px;
    border: 1px solid var(--white);
    margin: 0 5px 5px 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: var(--breezi-recessive);
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
}

.selector-tab {
    width: 20px;
    height: 100%;
    background: var(--breezi-blue);
    margin-right: 5px;
    border-right: 1px solid var(--white);
}

.selector-node:hover {
    color: var(--bg);
    background: var(--white);
}

.selector-node img {
    height: 28px;
    border: 1px solid var(--white);
}

.selector-node h4 {
    width: 40px;
    height: 28px;
    background: var(--breezi-recessive);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    font-size: .8em;
    border: 1px solid var(--white);
}

.selector-node:hover h4 {
    color: var(--prim);
}

.selector-node small {
    width: 55%;
    text-align: center;
}



.drop-selector {
    width: 210px;
    height: 200px;
    bottom: -193px;
    position: absolute;
    background: var(--white);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    color: var(--bg);
}

.drop-sel-item {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--bg);
    cursor: pointer;
}

.drop-sel-item:hover {
    background: var(--breezi-blue);
    border-bottom: 1px solid var(--white);
}

.drop-flag {
    width: 40px;
    margin-left: 5px;

}

.drop-sel-item h5 {
    color: var(--breezi-recessive);
    width: 38px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drop-sel-item small {
    width: 60%;
}

.ltd-sel-node {
    width: 45%;
    height: 30%;
    min-height: 70px;
    margin: 2.5px 0 2.5px 0;
    background: var(--breezi-recessive);
    border: 1px solid var(--white);
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding-right: 20px;
    cursor: pointer;
}

.ltd-sel-node:hover {
    background: var(--breezi-blue);
}

.ltd-icon {
    width: 80px;
    height: 100%;
    margin-right: 20px;
    border-right: 1px solid var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-blue);
}

.ltd-icon img {
    height: 45px;
}

.ltd-sel-node h5 {
    margin: 0px;
    width: 60%;
    text-align: left;
    font-size: .7em;
}

.selector-label {
    width: 100%;
    color: var(--breezi-blue);
    margin: 0;
    margin-bottom: -15px;
}

.full-width-selector {
    margin-top: -10px;
    width: 95%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}
