.App {
  margin: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: absolute;
  color: var(--white);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

main {
  width: 100vw;
  height: calc(100vh - 80px);
  margin: 0px;
  padding: 0px;
  display: flex;
  position: absolute;
  margin-top: 80px;
  max-height: 100vh;
  overflow: hidden;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}

.dark-overlay {
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(2.75px);
}

.panel {
  background: var(--window);
  border-radius: 5px;
  overflow: visible;
  /* border: 1px solid var(--white); */
}

.col {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.btn {
  width: 80px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btns {
  min-width: 250px;
  max-width: 450px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  border-top: 2px solid var(--white);
}

.btn {
  width: 120px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
  color: var(--white);
  cursor: pointer;
  margin: 0 10px 0 10px;
  font-size: 1.1em;
}

.transparent-btn {
  border: 1px solid var(--white);
}

.transparent-btn:hover {
  background: var(--breezi-blue-transparent);
}

.btn img {
  height: 30px;
}

.prim-btn {
  background: var(--prim-transparent);
  border: 2px solid var(--prim);
}

.prim-btn:hover {
  background: var(--prim);
}

.sec-btn {
  background: var(--sec-transparent);
  border: 2px solid var(--sec);
}

.sec-btn:hover {
  background: var(--sec);
}

.tri-btn {
  background: var(--tri-transparent);
  border: 2px solid var(--tri);
}

.tri-btn:hover {
  background: var(--tri);
}

.cancel-btn {
  background: var(--cancel-transparent);
  border: 2px solid var(--cancel);
}

.cancel-btn:hover {
  background: var(--cancel);
}

.crit-btn {
  background: var(--crit-transparent);
  border: 2px solid var(--crit);
}

.crit-btn:hover {
  background: var(--crit);
}

.ylw-btn {
  background: var(--ylw-transparent);
  border: 2px solid var(--ylw);
}

.ylw-btn:hover {
  background: var(--ylw);
}

.teal-util-btn {
  background: var(--teal-util-transparent);
  border: 2px solid var(--teal-util);
}

.teal-util-btn:hover {
  background: var(--teal-util);
}

.panel {
  background: var(--window);
  overflow: hidden;
}

.inactive-btn {
  background: darkgray;
  border: 2px solid gray;
  color: rgba(0, 0, 0, 0.35);
  cursor: default;
}

.panel-header {
  width: 100%;
  min-height: 80px;
  max-height: 80px; 
  background: var(--breezi-recessive);
  /* border-bottom: 1px solid var(--white); */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.popup-panel {
  flex-flow: row-wrap;
  position: absolute;
  width: 500px;
  height: 500px;
  overflow: visible;
}

.header-icon {
  width: 80px;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--breezi-blue);
  /* border-right: 1px solid var(--white); */
}

.header-icon img {
  height: 45px;
}

.header-title {
  width: calc(100% - 100px);
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header-title h2, .header-title h4 {
  width: 100%;
  margin: 0px;
  text-align: left;
}

.header-title h2 {
  color: var(--breezi-blue);
  text-decoration: underline;
}

.panel-body {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.card {
  width: 350px;
  height: 575px;
  background: var(--window);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  overflow: hidden;
  margin: 5px;
}

.card-header {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--breezi-recessive);
  /* border-bottom: 1px solid var(--white); */
}

.ob-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ob-card:hover {
  background: var(--bg);
}

.ob-card h1 {
  margin: 0;
}

.ob-card img {
  padding: 2%;
  width: 50px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.card-body {
  width: 100%;
  height: calc(100% - 55px);
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.card-corner {
  position: absolute;
  right: 2px;
  bottom: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100%;
  border: 1px solid var(--white);
}

.card-corner:hover {
  background: var(--breezi-blue);
}

.card-corner img {
  width: 25px;
}

.vert-sep {
  height: 60px;
  width: 2px;
  background: var(--breezi-blue);
}

@media (max-width:767px) {
  main {
    margin: 0px;
    /* height: calc(100% - 70px); */
    height: 100vh;
  }
}

@media (min-height:1200px) {
  .card {
    width: 450px;
    height: 700px;
    font-size: 1.3em;
  }
  .card-header {
    height: 12.5%;
  }
  .panel-header {
    min-height: 100px;
    max-height: 100px;;
  }
  .header-icon {
    min-height: 100px;
    max-height: 100px;
    min-width: 100px;
    max-width: 100px;
  }
}

@media (min-height:400px) and (max-height:880px) {
  main {
    margin-top: 4vh;
    height: 100%;
    margin-bottom: 10px;
  }
  .card {
    max-height: 87.5vh;
  }
}