.settings-overlay {
    top: 0;
    z-index: 100;
}

.settings {
    width: 35%;
    min-width: 410px;
    max-width: 600px;
    height: 96%;
    top: 2%;
    right: -100%;
    position: absolute;
    background: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px 0 0 10px;
    animation: settingsin .5s linear forwards;
    overflow: hidden;
    border: 1px solid var(--bg);
    border-right: none;
}

@keyframes settingsin {
    100% {
        right: 0;
    }
}

.close-settings {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 40px;
    height: 40px;
    /* background: var(--breezi-blue); */
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0);
    /* border: 3px solid var(--breezi-recessive); */
}

.close-settings:hover {
    background: var(--crit-transparent);
    border: 2px solid var(--crit);
}

.close-settings img {
    width: 20px;
}

.settings-toggle {
    width: 80%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 3%;
    align-self: flex-start;
    margin-right: -6%;
    margin-left: 80px;
    border-bottom: 1px solid var(--breezi-blue);
}

.settings-tri {
    height: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
}

.s-node {
    width: 40px;
    height: 40px;
    background: gray;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    margin-right: 3px;
    cursor: pointer;
}

.s-node:hover {
    background: var(--breezi-recessive);
}

.s-node img {
    width: 60%;
}

.active-s-node {
    background: var(--breezi-blue);
    width: 50px;
    height: 50px;
    cursor: default;
}

.active-s-node:hover {
    background: var(--breezi-blue);
}

.settings-toggle h3 {
    color: var(--breezi-blue);
    font-size: 1.5em;
}

.version-number {
    position: absolute;
    bottom: 20px;
}

.version-number small {
    color: var(--bg);
}

.settings-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.s-section {
    width: 85%;
    min-height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid gray;
}

.s-section-head {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.s-section-head h5 {
/*    color: var(--breezi-blue);*/
    color: #2869B9;
    margin: 10px;
}

.s-section-head img {
    width: 45px;
}

.s-section-content {
    width: 50%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.s-section-content h5 {
    color: var(--bg);
    margin: 5px;
    text-align: right;
    width: 250px;
    display: flex;
    justify-content: flex-start;
}

.s-tog-content {
    width: 60%;
    display: flex;
    justify-content: center;
}

.s-toggle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    min-width: 250px;
}

.s-toggle-container h5 {
    width: 65px;
}

.s-toggle {
    width: 70px;
    height: 30px;
    border: 3px solid #2869B9;
    border-radius: 30px;
    margin: 10px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.s-tog-node {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: #2869B9;
    position: absolute;
    left: 5px;
}

.active-s-tog {
    left: calc(100% - 30px);
}

.inactive-tog .s-toggle {
    border: 3px solid gray;
    cursor: default;
}

.inactive-tog .s-tog-node {
    background: gray;
}

.s-head-subtitle {
    margin: '-5px'; 
    padding-top: '5px';
    color: 'gray';
    border-top: '1px solid var(--gray)';
}

.s-section-content h5 {
    /* display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%; */
}

.s-section-content img {
    height: 20px;
    margin-left: '20px';
}

.disabled-s-node {
    background: gray;
    cursor: not-allowed;
}

.disabled-s-node:hover {
    background: gray;
}

@media (max-height: 880px) {
    .settings-overlay {
        z-index: 13;
    }
    .settings {
        justify-content: flex-start;

    }
    .settings-body {
        max-height: 520px;
        margin-top: 2%;
    }
    .settings .settings-card-btn {
        height: 40px;
        margin-bottom: 40px;
    }
}

.settings-form-overlay {
    position: fixed;
    right: 0;
    left: 0;
}

.checkbox-label {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--breezi-recessive);
    font-size: .8em;
}

.checkbox-label input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-bottom: 3.5px;
    background: rgba(0, 0, 0, 0);
}