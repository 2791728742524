.crp {
    width: 45%;
    height: 280px;
    margin-top: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    animation: waittrend .25s forwards;
    animation-delay: 1s;
}

.bubbles {
    width: 100%;
    height: 100%;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trend {
    width: 85%;
    height: 80%;
    animation: waittrend .25s forwards;
    animation-delay: 2s;
    opacity: 0;
    /* width: 100%;
    height: 100%; */
}

@keyframes waittrend {
    100% {
        opacity: 1;
    }
}

.crp-anim {
    width: 110%; 
    height: 110%; 
    display: flex; 
    justify-content: center; 
    opacity: 0;
    animation: chartfade .25s forwards;
    animation-delay: .5s;
}

@keyframes chartfade {
    100% {
        opacity: 1;
    }
}

.equip-trend-panel {
    width: 97.5%;
    min-height: 85%;
    max-height: 85%;
    margin-top: 20px;
    border: 1px solid gray;
    position: relative;
}

.equip-trend-controls {
    width: 360px;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    background: red;
    top: -61px;
    border: 1px solid gray;
    border-bottom: none;
    background: var(--window);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.equip-trend-toggles {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.equip-trend-tog {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background: var(--dark-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--white);
}

.equip-trend-tog img {
    height: 50%;
}

.equip-trend-dir {
    height: 40px;
    width: 40%;
    border-radius: 5px;
    margin: 3px;
}

.active-equip-trend-tog {
    background: var(--breezi-blue-transparent);
    cursor: pointer;
}

@media (min-height: 400px) and (max-height: 880px) {
    .equip-trend-controls {
        height: 30px;
        top: -31px;
    }
    .equip-trend-tog {
        /* height: 28px;
        width: 28px; */
        margin-top: 25px;
    }
}

@media (min-height:1200px) {
    .crp {
        width: 45%;
        height: 320px;
    }
    .highcharts-container, .highcharts-root, .highcharts-axis-labels {
        /* min-width: 400px;
        max-width: 400px;
        min-height: 400px;
        max-height: 400px;
        margin-top: 40px; */
        overflow: visible
    }
}

@media (min-height: 400px) and (max-height:700px) and (min-width: 1100px) {
    .highcharts-background {
        max-height: 200px;
        background: red;
    }
}