.card-frame {
    width: 350px;
    height: 575px;
    margin: 5px;
    perspective: 1000px;
    margin-bottom: 20px;
}

.card {
    width: 350px;
    height: 575px;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform-origin: center;
}

.card-face {
    width: 350px;
    height: 575px;
    border-radius: 5px;
    position: absolute;
    background: var(--window);
    backface-visibility: hidden;
    /* animation: fadeface .1s forwards;
    animation-delay: .25s;
    opacity: 0; */
}

@keyframes fadeface {
    100% {
        opacity: 1;
    }
}

.card-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* position: absolute; */
}

.card-load {
    width: 92%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.card-logo {
    width: 150px;
    margin: 20px;
}

@keyframes flip-left {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(180deg);
    }
}

@keyframes flip-right {
    0% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

.card-back {
    transform: rotateY(180deg);
    backface-visibility: visible;
}

.ap-flash {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg);
    border-radius: 100%;
/*    animation: flashes 1s forwards;*/
    border: 5px solid var(--breezi-blue);
    margin: 10px;
}

.ap-flash img {
    width: 45px;
    margin-top: 5px;
}

@keyframes flashes {
    0% {
        background: var(--bg);
    }
    40% {
        background: var(--bg);
    }
    50% {
        background: var(--breezi-blue);
    }
    57.5% {
        background: var(--bg);
    }
    60% {
        background: var(--bg);
    }
    70% {
        background: var(--breezi-blue);
    }
    75% {
        background: var(--bg);
    }
}

@keyframes flashes-crit {
    0% {
        background: var(--bg);
    }
    40% {
        background: var(--bg);
    }
    50% {
        background: var(--crit);
    }
    57.5% {
        background: var(--bg);
    }
    60% {
        background: var(--bg);
    }
    70% {
        background: var(--crit);
    }
    75% {
        background: var(--bg);
    }
}

.card-toggle {
    /* position: absolute; */
    width: 50%;
    height: 80px;
    position: absolute;
    bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25%;
    animation: fadein 2s forwards;
    opacity: 0;
}

@keyframes fadein {
    99% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.tog-connector {
    position: absolute;
    width: 80%;
    height: 3px;
    background: var(--breezi-blue);
    left: 10%;
}

.card-toggle-node {
    width: 35px;
    height: 35px;
    border: 3px solid var(--breezi-blue);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--window);
    z-index: 100;
    cursor: pointer;
}

.card-toggle-node:hover {
    background: var(--breezi-blue);
}

.card-toggle img {
    height: 27px;
}

.active-toggle-node {
    background: var(--breezi-recessive);
    width: 47px;
    height: 47px;
}

.card-status {
    height: 7px;
}

.full-card-status {
    width: 100%;
}

.left-card-status {
    width: 25%;
    align-self: flex-start;
}

.right-card-status {
    width: 25%;
    align-self: flex-end;
}

.mid-card-status {
    width: 50%;
}

.dp-card-header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* border-top: 2px solid var(--breezi-blue); */
    /* border-bottom: 2px solid gray; */
    position: relative;
    cursor: pointer;
}

.card-icon {
    background: var(--breezi-blue);
    height: 100%;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.card-icon img {
    height: 30px;
}

.dp-card-title {
    width: calc(100% - 70px);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.dp-card-title h4, .dp-card-title small {
    margin: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dp-card-title img {
    height: 15px;
    margin-right: 5px;
}

.card-settings-icon {
    width: 60px;
    margin: 0px;
    margin-top: 25px;
    background: none;
    /* border-left: 2px solid var(--breezi-blue); */
    cursor: pointer;
    background: var(--breezi-blue); 
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.card-settings-icon:hover {
    background: var(--breezi-blue);
}

.card-settings-icon img {
    height: 35px;
}

.dp-card-body {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dp-card-box {
    width: 90%;
    height: 42.5%;
    border: 1px solid var(--white);
    background: var(--dark-blue);
    margin-bottom: 2.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.double-dp-box {
    height: 87.5%;
    justify-content: center;
}

.dp-card-box img {
    height: 25px;
}

.double-dp-box img {
    height: 100px;
}

.dp-box-third {
    width: 32%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dp-box-third h3 {
    margin: 4px;
}

.dp-box-third h5 {
    margin: 10px;
}

.dp-box-quart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.hor-sep {
    height: 2px;
    width: 40px;
    background: var(--breezi-blue);
    margin: 5px;
}

.card-tms {
    position: absolute;
    bottom: 0px;
}

.crp-meas {
    height: 40px;
}

.card-header-subtitle {
    width: 90%;
    margin-bottom: -10px;
    height: 20px;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: var(--breezi-blue); */
    /* border-bottom: 1px solid var(--breezi-blue); */
}

.crp-card-body {
    margin: 0;
    width: 100%;
    margin-top: 20%;
    margin-right: 10%;
}

.dp-alerts-body {
    justify-content: flex-start;
    padding-top: 20px;
}

.dp-card-alert {
    width: 90%;
    max-width: 300px;
    height: 45px;
    margin: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
}

.dp-alert-icon {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dp-alert-icon img {
    height: 25px;
}

.dp-card-alert h5 {
    width: calc(100% - 45px);
    text-align: center;
}

.settings-card-body {
    justify-content: center;
}

.settings-card-btn {
    width: 75%;
    height: 60px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.close-card {
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
}

.close-card:hover {
    background: var(--breezi-blue);
}

.close-card img {
    width: 20px;
}

.dp-sp-indicator {
    position: absolute;
    right: 30%;
    margin-left: 10px;
    background: var(--orange-util-transparent);
    padding: 2px;
    border: 1px solid var(--white);
    cursor: none;
}   

.del-overlay {
    position: absolute;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(4px);
}

.del-overlay .text-input input {
    width: 95%;
}

.del-overlay small {
    text-align: center;
}

.card-rdg-content {
    width: 90%;
    height: 60%;
    margin-top: 15px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    background: var(--dark-blue);
    border: 2px solid gray;
    /* border-bottom: 2px solid var(--tri); */
}

.card-alert-content {
    flex-direction: column;
    justify-content: flex-start;
    height: calc(60% - 20px);
    padding-top: 20px;
}

.env-rdg-content {
    /* border: 2px solid var(--tri); */
}

.lg-rdg-content {
    height: 68%;
}

.card-rdg-tab {
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-rdg-tab small {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

.env-rdg-content .card-rdg-tab {
    background: var(--tri);
}

.card-rdg-rdgs {
    width: calc(100% - 30px);
    max-height: 100%;
}

.card-rdg-row {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 10px;
}

.dual-rdg-content {
    height: 35.5%;
    border: 2px solid gray;
    margin-top: 5%;
}

.equip-rdg-content {
    border: 2px solid var(--breezi-blue);
}
.equip-rdg-content .card-rdg-tab {
    background: var(--breezi-blue);
}

.card-served-by {
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 8px;
    cursor: default;
}

.card-served-by h5, .card-served-by small {
    margin: 2px;
}

.card-hvac-ref {
    min-width: 120px;
    height: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 2px solid var(--equip);
    border-radius: 10px;
    background: var(--bg);
    padding: 1.25%;
}

.card-served-by img {
    height: 15px;
}

.inactive-btn {
    cursor: not-allowed;
}

.diff-on-off {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 10px;
}

.diff-on-off img {
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
    margin-right: 5px;
}

.diff-on-off h5 {
    margin: 5px;
}

.placard-drawer {
    width: 350px;
    z-index: -1;
    position: absolute;
    left: 8px;
    top: 8px;
    background: #E6E6E6;
    animation: .5s placslide forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-delay: 1.5s;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    opacity: 0;
}

@keyframes placslide {
    100% {
        left: 340px;
        opacity: 1;
    }
}

.placard-drawer img {
    width: 90%;
}

@media (min-height:1200px) {
    .card small, .card h6 {
        font-size: .8em;
    }
}

@media (max-width:767px) {
    .card-frame {
        height: 510px;
        margin-bottom: 50px;
    }
    .card {
        height: 100%;
    }
    .card-face {
        width: 100%;
        height: 100%;
    }
}

@media (min-height: 400px) and (max-height: 880px) and (min-width: 768px) {
    .havc-sections .card-frame, .hvac-sections .card-body, .hvac-sections .card {
        max-height: 480px;
    }
    .card-face {
        height: 100%;
    }
    .card-face .card-served-by {
        margin-top: 0;
        position: absolute;
        bottom: 15%;
    }
    .card-face .card-served-by small {
        margin-top: 4px;
    }
    .card-face .card-hvac-ref {
        flex-direction: row;
        padding: 0;
        height: 18px;
        align-items: flex-start;
        border: 2px solid var(--equip);
        padding-bottom: 4px;
        margin-bottom: 3px;
    }
    .card-face .card-served-by img {
        width: 15px;
        height: 15px;
        margin-top: 3px;
    }
    .mini-rdg-row {
        padding-bottom: 8px;
    }
    .dual-rdg-content {
        padding: 3px;
        margin-top: 10px;
    }
}

@media (min-height: 1200px) {
    .card-frame, .card, .card-face {
        width: 450px;
        height: 700px;
    }
    .dp-card-header {
        height: 70px;
    }
    .dp-card-title {
        margin-left: 20px;
        margin-top: 10px;
    }
    .card-settings-icon {
        width: 70px;
        height: 70px;
    }
    .card-rdg-content {
        height: 62.5%;
    }
    .lg-rdg-content {
        height: 75%;
    }
    .dp-card-title small {
        margin-top: 4px;
    }
    .dp-card-title img {
        height: 20px;
        margin-right: 15px;
    }
    .card-tms small {
        font-size: .65em;
    }
    .card-toggle {
        margin-bottom: 15px;
    }
    .card-toggle-node {
        width: 40px;
        height: 40px;
    }
    .active-toggle-node {
        width: 55px;
        height: 55px;
    }
}

@media (min-height:1200px) {
    .placard-drawer {
        animation: .5s lgplacslide forwards;
        animation-delay: 1.5s;
    }
    @keyframes lgplacslide {
        100% {
            left: 450px;
            opacity: 1;
        }
    }
}

@media (min-height: 400px) and (max-height:700px) and (min-width: 1100px) {
    .zone-cards .card, .zone-cards .card-frame, .zone-cards .card-face {
        width: 280px;
        height: 420px;
        font-size: .9em;
    }
    .diff-reading-node {
        max-height: 100px;
        margin-top: 5px;
    }
    .dp-card-header {
        max-height: 35px;
        margin-top: 5px;
        font-size: 1.2em;
    }
    .card-settings-icon {
        height: 40px;
        margin-bottom: 10px;
    }
    .card-toggle-node {
        height: 20px;
        width: 20px;
    }
    .card-header-subtitle {
        display: none;
    }
    .card-rdg-content {
        height: 75%;
    }
    .active-toggle-node {
        width: 30px;
        height: 30px;
    }
    .card-content {
        height: 80%;
    }
    .zone-cards .sm-rdg-row {
        margin-bottom: 20px;
    }
    .zone-cards .card-toggle {
        min-width: 50%;
        left: 0%;
    }
    .zone-cards .card-toggle img {
        height: 80%;
    }
    .card-header {
        max-height: 50px;
    }
}