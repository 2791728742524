.reading-node {
    width: 140px;
    height: 140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 4px;
}

.mini-reading-node {
    width: 30%;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    position: relative;
    margin: 2%;
}

.reading-icon {
    /* width: 40px; */
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.crit-reading-node .reading-icon {
    background: var(--crit-transparent);
    border-bottom: 2px solid var(--crit);
}
.cancel-reading-node .reading-icon {
    background: var(--cancel-transparent);
    border-bottom: 2px solid var(--cancel);
}
.prim-reading-node .reading-icon {
    background: var(--prim-transparent);
    border-bottom: 2px solid var(--prim);
}

.inactive-reading-node {
    border: 2px solid var(--breezi-blue);
}

.inactive-reading-node .reading-icon {
    background: var(--breezi-blue-transparent);
    border-bottom: 2px solid var(--breezi-blue);
}
.reading-icon img {
    width: 25px;
}

.status-dots {
    width: calc(100% - 40px);
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mini-reading-node .status-dots {
    width: 20px;
    margin-left: 5%;
    height: 58%;
    height: 100%;
    flex-direction: column;
    transform: rotateX(180deg);
}

.mini-reading-node .reading-icon {
    width: 100%;
}

.mini-reading-val {
    width: 50%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 55%;
    left: 10px;
}

.mini-reading-val h1, .mini-reading-val small {
    margin: 0;
}

.status-dot {
    width: 5px;
    height: 5px;
    border-radius: 100%;
}
.inactive-dot {
    background: gray;
}
.crit-dot, .cancel-dot, .prim-dot {
    width: 15px;
    height: 15px;
}
.crit-dot {
    background: var(--crit);
}
.cancel-dot {
    background: var(--cancel);
}
.prim-dot {
    background: var(--prim);
}

.reading-node h1, .reading-node small {
    width: 100%;
    height: 20px;
}
.reading-node h1 {
    font-size: 2.2em;
    margin-top: 12px;
}
.reading-node small {
    margin-top: -15px;
    margin-bottom: 15px;
}
.crit-reading-node h1, .crit-reading-node small {
    color: var(--crit);
}
.cancel-reading-node h1, .cancel-reading-node small {
    color: var(--cancel);
}
.prim-reading-node h1, .prim-reading-node small {
    color: var(--prim);
}





.reading-header {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-blue);
    /* border-bottom-left-radius: 6px; */
    /* border-bottom-right-radius: 6px; */
}

.reading-header img {
    height: 65%;
}

.reading-node h4 {
    color: var(--breezi-blue);
    font-weight: 400;
    font-size: 1.1em;
}

.crit-reading-node {
    border: 2px solid var(--crit);
}
.crit-reading-node .reading-header {
    background: var(--crit-transparent);
    border-bottom: 2px solid var(--crit)
}
.crit-reading-node h4, .crit-reading-node h5 {
    color: var(--crit);
    line-height: 10px;
}

.cancel-reading-node {
    border: 2px solid var(--cancel);
}
.cancel-reading-node .reading-header {
    background: var(--cancel-transparent);
    border-bottom: 2px solid var(--cancel)
}
.cancel-reading-node h4, .cancel-reading-node h5 {
    color: var(--cancel);
    line-height: 10px;
}

.prim-reading-node {
    border: 2px solid var(--prim);
}
.prim-reading-node .reading-header {
    background: var(--prim-transparent);
    border-bottom: 2px solid var(--prim)
}
.prim-reading-node h4, .prim-reading-node h5 {
    color: var(--prim);
    line-height: 10px;
}

.reading-body {
    height: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.diff-reading-node {
    width: 100%;
    height: 150px;
    border-radius: 5px;
}

.diff-reading-node .reading-header {
    display: flex;
    justify-content: space-around;
}

.diff-maint {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.diff-maint h5, .diff-maint h6 {
    margin: 5px;
}

.diff-maint h6 {
    width: 93%;
    background: var(--dark-blue);
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.diff-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.diff-reading {
    display: flex;
    justify-content: center;
    align-items: center;
}
.diff-reading img {
    width: 25px;
    margin-right: 8px;
}



.diff-reading small, .diff-reading h6 {
    font-size: .8em;
}


/* FOR CARD/ENV.TSX */
.content-half {
    width: 100%;
    height: calc(50% - 2px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.env-temp-hum {
    width: 140px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
}

.env-temp {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border-top-left-radius: 100%;
    border-top-right-radius: 100%; */
}

.env-hum {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%; */
    background: blue;
}

.gauge {
    width: 50%;
    height: 130px;
    border-radius: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    transform: rotateX(180deg);
    margin: 8px;
    margin-bottom: 0;
}

.gauge-readout {
    width: 100px;
    height: 101px;
    border-radius: 100%;
    background: var(--dark-blue);
    position: absolute;
    z-index: 100;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transform: rotateX(180deg);
}

.gauge-icon {
    width: 20px;
    margin-top: 8px;
    margin-bottom: 5px;
}

.no-touchy {
    width: 100%;
    height: 100%;
    position: absolute;
}

.vert-reading {
    height: 90%;
    min-width: 120px;
    max-width: 100px;
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
}

.vert-reading-header {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vert-reading img {
    height: 30px;
}

.vert-reading-body {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vert-reading h1 {
    font-size: 2.2em;
    font-weight: lighter;
    margin: 0;
}

.vert-reading h4 {
    font-weight: lighter;
    margin: 0;
}

.diff-gauge {
    width: 100px;
    margin: 0px;
    display: flex;
}

.diff-gauge .gauge-readout {
    width: 70px;
    height: 69px;
}

.diff-gauge img {
    height: 20px;
    width: auto;
}

.mini-rdg-row {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    margin-bottom: -15px;
}

.diff-mini-node {
    height: 50px;
    width: 60px;
}

.mini-rdg-row .mini-reading-icon {
    height: 20px;
}

.mini-rdg-row img {
    height: 20px;
    width: auto;
}

.mini-rdg-text {
    width: calc(100% - 22px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mini-rdg-text small {
    margin: -3px;
}

@media (min-height: 400px) and (max-height: 880px) {
    .vert-reading {
        min-width: 90px;
        max-width: 90px;
        margin-left: 15px;
    }
    .diff-reading-node {
        max-height: 110px;
        margin-top: 10px;
    }
    .diff-on-off {
        bottom: 15%;
    }
}

@media (min-height:1200px) {
    .gauge {
        height: 160px;
        margin-bottom: 30px;
    }
    .gauge-readout {
        height: 135px;
        width: 135px;
    }
    .vert-reading {
        min-width: 160px;
        min-height: 160px;
        max-width: 160px;
        max-height: 160px;
        margin: 2%;
    }
    .mini-reading-node {
        height: 140px;
        font-size: .9em;
    }
    .card-hvac-ref {
        min-width: 235px;
        max-width: 90%;
        font-size: .9em;
    }
    .card-hvac-ref img {
        margin-left: 10px;
    }
    .crp-card-body {
        margin-bottom: 50px;
    }
    .dp-card-alert {
        height: 60px;
    }
    .dp-alert-icon {
        height: 100%;
        width: 60px;
    }
}

@media (min-height: 400px) and (max-height:700px) and (min-width: 1100px) {
    .gauge {  
        width: 50%;
    }
    .gauge-readout {
        max-width: 75px;
        max-height: 75px;
    }
    .gauge-readout img {
        margin-top: 15px;
    }
    .diff-reading-node .reading-header img {
        max-height: 30px;
    }
    .mini-reading-node {
        max-height: 80px;
        min-height: 80px;
    }
}

@media (max-width:767px) {
    .card-rdg-row, .no-touchy {
        justify-content: space-around;
    }
    .gauge {
        width: 130px;
        height: 130px;
    }
    .no-touchy {
        width: 150px;
        height: 150px;
    }
}