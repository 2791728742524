.custom-tooltip {
    max-width: 450px;
    z-index: 100;
    max-height: 450px;
}

.tooltip-placard img {
    max-width: 300px;
}

.tooltip-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tooltip-info-header {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid var(--breezi-blue);
}

.tooltip-info-header img {
    height: 30px;
    background: var(--breezi-blue);
    padding: 3px;
    margin-right: 10px;
}

.tooltip-info-header h4 {
    font-weight: lighter;
    color: var(--breezi-blue);
}

.tooltip-info-content {
    width: 90%;
}

.tooltip-info p {
    text-align: left;
}

.equip-tooltip {
    min-height: 150px;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.equip-tooltip-header {
    width: 100px;
}

.equip-tooltip-rdgs {
    width: 100%;
    height: 200px;
    display: flex;
    flex-wrap: wrap;
}

.equip-tooltip-rdg {
    width: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5px;
    padding: 2px;
    border-radius: 4px;
}

.equip-tooltip-rdg img {
    height: 20px;
}

.faux-tt {
    width: 98%;
    height: auto;
    position: absolute;
    top: 85%;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(5px);
    z-index: 2;
    border: 2px solid var(--cancel-transparent);
    border-top: none;
    opacity: 0;
    animation: fauxfade .5s forwards;
    animation-delay: 1s;
}

@keyframes fauxfade {
    100% {
        opacity: 1;
    }
}

.faux-tt small {
    height: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--cancel);
}