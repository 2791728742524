.sp {
    width: 100%;
    max-width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: var(--window);
}

.sp-header {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--white);
}

.sp-icon {
    width: 70px;
    height: 70px;
    background: var(--breezi-recessive);
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--white);
}

.sp-icon img {
    width: 30px;
}


.sp-title h2 {
  font-size: 1.2em;
}

.sp-title small {
    width: 100%;
    text-align: left;
}

.sp-tabs {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--white);
}

.sp-tab {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg);
    border-left: 1px solid var(--white);
    border-right: 1px solid var(--white);
    margin: 2%;
    cursor: pointer;
}

.sp-tab:hover {
  background: var(--breezi-blue);
}

.sp-tab img {
    height: 40%;
}

.active-sp-tab {
    background: var(--prim-transparent);
    width: 35%;
    cursor: default;
}

.active-sp-tab:hover {
  background: var(--prim-transparent);
}

.active-sp-tab img {
    height: 60%;
}

.sp-body {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rdg-range {
    width: 95%;
    min-height: 100px;
    border: 1px solid var(--breezi-blue);
    margin-top: 10px;
    margin-bottom: -2.5px;
    background: var(--bg);
    padding-bottom: 10px;
}

.rdg-range-header {
    width: 100%;
    height: 27.5px;
    background: var(--breezi-blue);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rdg-range-header img {
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.sp-label {
    width: 100%;
    height: 70%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.range-tier {
  width: 20px;
  height: 7px;
}

.range-spectrum {
  width: 100%;
  height: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.rdg-range-text {
  width: 100%;
  height: 18px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -14px;
}

.rdg-range-text small {
  margin: 4px;
  height: 100%;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
}

.sp-btns {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}

.sp-btn {
  width: 30%;
  margin: .5%;
  height: 20px;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;
  font-size: .8em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rc-slider {
  pointer-events: none;
}

.rc-slider-handle {
  pointer-events: auto;
}

.reset-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reset-overlay h5 {
  width: 90%;
}