.floor-main {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
}

/* .floor-util-panel {
    width: 22.5%;
    height: 90%;
    position: relative;
    display: none;
} */

.floor-zones-panel {
    width: 82%; 
    position: relative;
    border: 1px solid var(--white);
    padding: .5%;
}

.floor-util-tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--white);
    padding-top: 3px;
}

.floor-util-tab {
    width: 23%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--white);
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    margin: 0 2px 0 2px;
}

.floor-util-tab img {
    height: 60%;
}

.floor-util-panel header {
    justify-content: center;
}


.floor-util-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 20px;
}

.floor-util-rdg {
    width: 43%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    border: 2px solid var(--breezi-blue);
    background: var(--breezi-recessive);
    border-radius: 5px;
}

.floor-util-rdg h4 {
    width: 100%;
    height: 30px;
    margin: 0;
    background: var(--breezi-blue);
    display: flex;
    justify-content: center;
    align-items: center;
}

.floor-util-rdg img {
    height: 45px;
}

.floor-util-rdg small {
    margin-bottom: 5px;
}

.floor-zones-subtitle {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.floor-zones-subtitle small {
    width: 15%;
    text-align: left;
    color: var(--breezi-blue);
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px solid var(--breezi-blue);
}

.zones-body {
    width: 100%;
    height: 90%;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
}

.floor-zones-panel .panel-body {
    height: 100%;
    justify-content: flex-start;
    padding: 2%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.zone-row {
    width: 100%;
    height: 395px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    border-radius: 5px;
    border: 3px solid var(--breezi-blue);
    padding-left: 80px;
}

.floor-zones {
    width: 100%;
    display: flex;
    /* margin-bottom: 100px; */
    background: var(--window);
    position: relative;
}

.floor-zones-header {
    min-width: 80px;
    max-width: 80px;
    height: 100%;
    background: var(--breezi-blue);
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center;
    left: 0;
    z-index: 10;
    border-radius: 0px;
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.floor-zones-header h4 {
    transform: rotate(-90deg);
    text-align: left;
}

.floor-zones-header h4 {
    font-size: 1em;
    margin-bottom: 50px;
}

.floor-zones-header img {
    width: 47.5px;
}

.floor-zone {
    min-width: 355px;
    width: 355px;
    min-height: 355px;
    height: 355px;
    background: var(--bg);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    margin-left: 20px;
    border-radius: 5px;
    /* border: 1px solid var(--white); */
    position: relative;
    overflow: visible;
    border: 3px solid var(--breezi-blue);
}

.floor-zone-status {
    display: none;
    /* width: calc(100% + 6px);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 13px;
    background: var(--breezi-blue);
    position: absolute;
    top: -3px; */
}

.floor-zone-header {
    width: 90%;
    height: 50px;
    display: flex;
    justify-content: sapce-around;
    align-items: flex-end;
    margin-top: 10px;
    border-bottom: 3px solid var(--breezi-blue);
    cursor: pointer;
    margin-top: 20px;
}

.floor-zone-title {
    /* width: 80%; */
    width: 100%;
    margin-left: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 6px;
}

.floor-zone-header h3 {
    margin: 5px;
    width: 100%;
    text-align: left;
    color: var(--breezi-blue);
    font-size: .95em;
}

.floor-zone-header small {
    width: 80%;
    text-align: left;
    border-top: 1px solid var(--white);
    padding-top: 7px;
    padding-left: 4px;
}

.floor-zone-icon {
    height: 100%;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-blue);
}

.floor-zone-icon img {
    height: 75%;
}

.floor-equip {
    border: 3px solid var(--equip);
}

.floor-equip .floor-zone-title {
    width: 100%;
    margin-left: 20px;
}

.floor-equip .floor-zone-status {
    background: var(--equip);
}

.floor-equip .floor-zone-header {
    border-bottom: 2px solid var(--equip);
}

.floor-equip .floor-zone-header h3 {
    color: var(--equip);
}

.floor-equip .floor-zone-icon {
    background: var(--equip);
}

.floor-equip .floor-zone-header small {
    font-size: .7em;
}

.ob-floor-zone {
    position: relative;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
    background: var(--bg);
    border: 3px solid var(--breezi-blue);
    cursor: pointer;
    z-index: 0;
}

.ob-floor-zone:hover {
    background: var(--breezi-blue);
}

.ob-floor-zone img {
    width: 70px;
}

.ob-floor-zone h1 {
    margin: 0;
    font-size: 3em;
}

.ob-floor-zone h4 {
    margin: 0;
}

.floor-equips .ob-floor-zone {
    border: 3px solid var(--equip);
}

.floor-equips .ob-floor-zone:hover {
    background: var(--equip);
}

.ob-floor-node {
    border: 1px solid rgba(0, 0, 0, 0);
    background: var(--bg);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ob-floor-node:hover {
    background: var(--breezi-blue);
}

.floor-equips .zone-row {
    border: 3px solid var(--equip);
    
}

.floor-equips .ob-floor-node:hover {
    background: var(--equip);
}

.floor-equips .floor-zones-header {
    background: var(--equip);
}

.floor-zones-footer {
    width: 80px;
    height: calc(100% - 6px);
    /* border: 3px solid var(--breezi-blue); */
    /* border-left: 1px solid var(--breezi-blue); */
    background: var(--window);
    position: absolute;
    right: 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.floor-equips .floor-zones-footer {
    /* border: 3px solid var(--equip); */
    /* border-left: 1px solid var(--equip); */
}

.footer-settings-node {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border: 1px solid var(--white);
    border-radius: 5px;
    /* background: rgba(0, 0, 0, 0.5); */
    cursor: pointer;
    position: absolute;
    top: 10px;
}

.footer-settings-node:hover {
    background: var(--cancel-transparent);
}

.footer-settings-node img {
    width: 30px;
}

.footer-settings-node h1, .footer-settings-node h4 {
    margin: -2px;
    font-weight: lighter;
}

.ob-floor-node img {
    width: 50px;
}

.ob-floor-node small {

}

.ob-floor-zone img {
    margin: 12px;
}

.ob-floor-zone small {
    color: var(--breezi-blue);
}

.floor-util-props {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.floor-info-node {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.floor-info-node small {
    width: 40%;
    text-align: center;
    background: var(--sec);
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.floor-info-node h4 {
    width: 51%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--breezi-blue);
    color: var(--breezi-blue);
}

.floor-info-node input {
    width: 98%;
    border: none;
    background: none;
    border: 1px solid var(--white);
    height: 40px;
}

.floor-info-input {
    width: 50%;
    height: 42px;
    position: relative;
    margin-bottom: 2px;
}

.floor-info-input input {
    color: var(--white);
    font-family: Comfortaa, sans-serif;
    font-size: 1.2em;
    text-align: center;
}

.info-sheath {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    background: var(--window);
    border: 1px solid var(--white);
    margin-bottom: 2px;
}

.floor-info-input img {
    width: 20px;
}

.floor-info-btns {
    width: 90%;
    height: 60px;
    border: 1px solid var(--cancel);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.floor-info-btns small {
    color: var(--cancel);
}

.floor-info-btns .btn {
    height: 30px;
    width: 80px;
    margin: 2px;
}

.floor-btn-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.floor-zone-body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: -10px;
}

.floor-dps {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    align-content: center;
    margin-top: 20px;
    padding: 1%;
}

.floor-dp-frame {
    width: 73px;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: none;
    /* margin: 1px; */
}

.floor-dp {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--white);
    background: var(--bg);
    border-radius: 100%;
}

.unassigned-floor-dp {
    background: var(--breezi-blue-transparent);
    border: 2px solid var(--breezi-blue);
}

.drag-dp {
    transform: translate(0, 0);
    cursor:copy;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.drag-dp:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.floor-dp img {
    width: 80%;
}

.ob-floor-dp {
    cursor: pointer;
}

.ob-floor-dp:hover {
    background: var(--breezi-blue);
}

.floor-ob-datapoint {
    width: 100%;
    height: 100%;
    background: var(--bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ob-apsn {
    width: 80%;
}

.ob-apsn .btns {
    padding-top: 40px;
}

.ob-apsn label {
    position: relative;
}

.ob-dp-input {
    width: 200px;
    height: 50px;
    border: 1px solid var(--breezi-blue);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ob-dp-input input {
    font-size: 1.2em;
    width: 70%;
    background: none;
    border: none;
    border-bottom: 1px solid var(--white);
    color: var(--white);
}

.check-btn {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--prim);
    border-radius: 100%;
    position: absolute;
    right: 2%;
    cursor: pointer;
}

.check-btn img {
    width: 20px;
}

.selector-ob-apsn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.selector-ob-apsn small {
    width: 80%;
    text-align: center;
}

.ob-apsn-config {
    width: 110px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: var(--breezi-recessive);
    border: 1px solid var(--breezi-blue);
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.ob-apsn-config:hover {
    background: var(--breezi-blue);
}

.ob-apsn-config img {
    height: 35px;
    margin-bottom: -25px;
}

.apsn-review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.apsn-review h3 {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid var(--prim);
    color: var(--prim);
    margin-bottom: 10px;
}

.ob-ap-review {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 7px;
}

.ob-ap-review h4 {
    width: 100%;
    height: 20px;
    background: var(--breezi-blue);
    margin: 6px;
}

.equip-zone-sections {
    width: 95%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.snapshot {
    height: 97.5%;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: visible;
    cursor: pointer;
}
.snapshot-node {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: grownode .25s forwards;
}

@keyframes grownode {
    100% {
        width: 40px;
        height: 40px;
        background: var(--bg);
    }
}

.snapshot-node h5, .snapshot-node img {
    animation: fadenode .25s forwards;
    opacity: 0;
    animation-delay: .15s;
}

@keyframes fadenode {
    100% {
        opacity: 1;
    }
}

.equip-zone-section {
    width: 25px;
    height: 30px;
    background: var(--breezi-blue);
    border-radius: 12px;
    margin: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.snapshot img {
    height: 60%;
}

.equip-zone-sections small {
    position: absolute;
    bottom: 32%;
    font-size: .7em;
    font-weight: lighter;
    color: darkgray;
}

.floor-dp-tooltip {
    position: absolute;
    height: 60px;
    width: 0px;
    left: 52px;
    background: var(--bg);
    border: 1px solid var(--breezi-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: widen .5s forwards;
}

.floor-dp-tooltip:hover {
    background: var(--cancel);
}

@keyframes widen {
    100% {
        width: 200px;
    }
}

.appear-text {
    animation: appeartext .25s forwards;
    animation-delay: .5s;
    opacity: 0;
}

@keyframes appeartext {
    100% {
        opacity: 1;
    }
}

.floor-zone-served-by {
    width: 85%;
    height: 28px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    cursor: pointer;
}

.floor-zone-served-by img {
    height: 20px;
    margin-left: 10px;
    margin-right: 15px;
}

.floor-zone-served-by:hover {
    background: var(--breezi-blue)
}

.not-served small {
    color: gray;
}

.not-served img {
    opacity: .65;
}

.not-served:hover {
    background: var(--breezi-recessive);
}

.not-served:hover small {
    color: var(--white);
}

.not-served:hover img {
    opacity: 1;
}

.floor-settings-tog {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 85px;
    right: 10px;
    z-index: 2;
    cursor: pointer
}

.floor-settings-tog:hover {
    background: var(--sec);
}

.floor-settings-tog img {
    height: 25px;
}

.floor-sp-tog {
    right: 45px;
}

.floor-sp-tog:hover {
    background: var(--tri);
}

.floor-crp {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.floor-settings {
    width: 0px;
    height: 100%;
    /* background: var(--white); */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    right: 0;
    top: -1px;
}

.floor-settings-node {
    width: 50px;
    height: 50px;
    border: 1px solid var(--white);
    background: var(--breezi-blue-transparent);
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
}

.floor-settings-node:hover {
    background: var(--breezi-blue);
}

.floor-settings-node img {
    height: 35px;
}

.floor-served-by {
    width: 80%;
    margin-right: 10%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 5px;
}

@media (min-height: 400px) and (max-height: 880px) and (min-width: 768px) {
    .floor-zone {
        min-width: 290px;
        max-width: 290px;
        min-height: 290px;
        max-height: 290px;
    }
    .zone-row {
        height: 300px;
    }
    .floor-served-by {
        top: 70px;
        width: 100%;
        justify-content: flex-end;
        left: 10px;
        max-height: 40vh;
    }
    .card-hvac-ref {
        background: none;
        border: none;
        /* width: 80px; */
        justify-content: center;
    }
    .card-hvac-ref img {
        width: 12.5px;
        height: auto;
        margin-left: 5px;
        margin-bottom: 1px;
    }
    .floor-zone {
        min-height: 285px;
        max-height: 285px;
        border: 1px solid var(--breezi-blue);
        margin: 5px;
    }
    .floor-equip {
        border: 1px solid var(--equip);
    }
    .ob-floor-zone {
        border: 1px solid var(--breezi-blue);
    }
    .floor-equips .ob-floor-zone {
        border: 1px solid var(--equip);
    }
    .floor-zone .card-served-by {
        top: -80px;
        left: 5px;
        font-size: .8em;
        max-width: 80px;
    }
    .floor-zone .card-hvac-ref {
        display: none;
    }
    .floor-zones-panel {
        border: none;
        width: 90vw;
        margin-bottom: 20px;
    }
    .floor-settings {
        display: none;
    }
    /* .card-hvac-ref img {
        margin: 0;
        width: 20px;
    }
    .card-hvac-ref small {
        margin-top: 5px;
        margin-bottom: -10px;
    }
    .floor-zone-header {
        height: 50px;
        margin-top: 12px;
    }
    .floor-zone-status {
        height: 6px;
    } */
}

@media(max-width:767px) {
    .floor-main {
        justify-content: flex-start;
        padding: 0;
        width: 100%;
        padding: 0;
    }
    .mobile-floor-toggle {
        width: 95vw;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: 80px;
        overflow: hidden;
    }

    .mobile-floor-tog {
        width: 45%;
        height: 80%;
        margin-bottom: 7.5px;
        margin-left: 2.5%;
        margin-right: 2.5%;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .active-floor-tog {
        height: 90px;
    }

    .mobile-floor-tog img {
        height: 35px;
    }

    .active-floor-tog img {
        margin-top: 5px;
    }

    .floor-zones-panel {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0;
        margin-left: 2%;
    }
    .floor-zones-header {
        display: none;
    }
    .floor-zones {
        align-self: center;
        margin: 0;
        padding: 0;
        margin-top: 130px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        /* width: auto;
        margin-right: 2.5%;
        max-height: 85vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: none; */
    }
    .zone-row {
        width: 100%;
        height: calc(80vh - 50px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding: 0;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .floor-zone {
        margin-right: 15px;
    }
}

@media (min-height: 1200px) {
    .floor-main {
        font-size: 1.2em;
    }
    .floor-zones-panel {
        
    }
    .floor-zones {
        height: auto;
    }
    .zone-row {
        height: 500px;
    }
    .floor-zone {
        width: 470px;
        height: 470px;
    }
    .floor-zone-header {
        height: 100px;
    }
    .floor-zone-icon {
        width: 150px;
        height: 100px;
    }
    .ob-floor-zone img {
        height: 20%;
        width: auto;
        margin-bottom: 30px;
    }
    .card-hvac-ref {
        min-width: 200px;
        padding: 2%;
    }
    .card-hvac-ref img {
        height: 25px;
        margin-bottom: 2px;
    }
    .floor-settings {
        height: 160px;
        justify-content: space-between;
        margin-top: 1px;
        /* height: 50%;
        display: flex;
        flex-direction: column;
        background: red;
        justify-content: flex-start; */
    }
    .floor-settings-node {
        width: 80px;
        height: auto;
    }
    .floor-settings-node img {
        height: 50px;
        padding: 20%;
    }
}

@media (min-height: 400px) and (max-height:700px) and (min-width: 1100px) {
    .floor-zones-panel {
        border: none;
        margin-top: 20px;
        max-height: 90vh;
    }
    .zone-row {
        max-height: 40vh;
    }
    .floor-zone {
        min-width: 240px;
        max-width: 240px;
        min-height: 240px;
        max-height: 240px;
        overflow: hidden;
    }
    .floor-zone-header {
        height: 25px;
        z-index: 10;
        font-size: .9em;
    }
    .floor-zone-header h3, .floor-zone-header p {
        margin: 0;
    }
    .floor-zone-icon {
        height: 40px;
    }
    .floor-zone-title small {
        margin-bottom: 5px;
    }
    .floor-settings {
        display: none;
    }
    .floor-zone .card-served-by {
        display: none;
    }
    .floor-zone-title {
        margin-left: 10px;
    }
}

@media (min-height: 400px) and (max-height:880px) {
    /* .floor-zones-panel {
        margin-top: 20px;
    } */
}