.form-main {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  margin: 0;
  top: 0;
  position: fixed;
}

.form-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.form-panel header {
  position: relative;
}

.panel-close {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20%;
  right: 2%;
  cursor: pointer;
  border-radius: 100%;
}

.panel-close:hover {
  background: var(--breezi-blue);
}

.panel-close img {
  width: 100%;
  height: 100%;
}

.form-body {
  width: 100%;
  height: 90%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: row-wrap;
  padding-bottom: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.text-input {
  width: 210px;
  height: 40px;
  display: flex;
  flex-direction: column;;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin: 10px;
  font-size: .8em;
}

.form-prefill {
  margin-top: 1px;
  width: 100%;
  height: 40px;
  border: 2px solid var(--white);
  background: var(--breezi-recessive);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--breezi-blue);
  cursor: pointer;
  margin-bottom: 4px;
}

.form-prefill:hover {
  background: var(--cancel-transparent);
  color: var(--window);
}

.form-prefill img {
  height: 20px;
  margin-right: 20px;
  margin-left: 10px;
}

.input-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.text-input input {
  width: 100%;
  height: 30px;
  padding: 5px;
  background: none;
  border: 2px solid var(--breezi-blue);
  font-size: 1.25em;
  color: var(--white);
  font-family: Comfortaa, sans-serif;
  font-weight: 400;
  margin-bottom: 4px;
  /* border-radius: 4px; */
}

.text-input input::-webkit-inner-spin-button,
.text-input input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Optional */
}

  .input-pre {
    min-width: 30px;
    padding: 0 10px 0 10px;
    height: 40px;
    background: var(--breezi-recessive);
    border: 2px solid var(--breezi-blue);
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
  }

  .input-box input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-aft {
    cursor: pointer;
    border-left: none;
    border-right: 2px solid var(--breezi-blue);
  }

  .input-aft:hover {
    background: var(--breezi-blue);
  }

  .input-aft img {
    height: 25px;
  }

  .input-err {
    height: 40px;
    width: 206px;
    position: absolute;
    bottom: 7px;
    left: 2px;
    background: var(--bg);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    animation: flashalert .5s forwards;
  }

  .input-err h6 {
    color: orange;
    font-size: .8em;
    text-align: center;
  }

@keyframes flashalert {
  50% {
    background: var(--white);
  }
  100% {
    background: var(--bg);
  }
}

.seg-link {
  position: absolute;
  text-align: right;
  right: 5%;
  bottom: 10px;
  color: var(--cancel);
  font-family: 'Times New Roman', Times, serif;
  cursor: pointer;
}

.seg-link:hover {
  color: var(--white);
}

.locale-validator {
  width: 100%;
  height: 44%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border: 2px solid var(--breezi-blue);
}

.lv-icon {
  width: 50%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lv-rows {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.lv-row {
  width: 206px;
  margin-left: 12px;
  height: 46px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 2px solid var(--white);
}

.lv-row img {
  height: 30px;
}

.lv-rows label {
  margin-top: 10px;
  margin-bottom: 10px;
}

.lv-cond {
  width: 120px;
  background: var(--breezi-blue);
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid var(--white);
}

.lv-cond img {
  margin: -17px;
  margin-top: -10px;
  width: 50px;
}

.locale-validator h4 {
  margin: 5px;
}

.lv-weather {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.lv-temp-hum {
  width: 80px;
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.lv-rdg {
  width: 100%;
  height: calc(50% - 2px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 10px solid var(--white);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-right: 4px;
  background: var(--breezi-recessive);
  border: 1px solid var(--white);
}

.lv-rdg img {
  height: 17px;
  margin-right: 10px;
  margin-left: 5px;
}

.lv-time {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-bottom: 2px solid var(--breezi-blue);
}

.lv-time img {
  height: 32px;
  margin-right: 20px;
}

.lv-tz {
  width: 40%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--breezi-blue);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.zone-form {
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.notes-container {
  width: 95%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.notes-container h3 {
  color: lightgray;
  border-bottom: 1px solid lightgray;
  display: flex;
  align-self: flex-start;
  font-size: .9em;
  font-weight: lighter;
}

.notes-container textarea {
  width: 95%;
  min-height: 120px;
  background: none;
  border: 1px solid var(--breezi-blue);
  color: var(--white);
  font-size: 1.2em;
}

@media(max-width:850px) {
  .form-panel {
    max-width: 98vw;
  }
  .form-panel .selector-node {
    width: 90%; 
  }
  .ltd-sel-node { 
    width: 90%;
  }
  .lg-selector {
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 50%;
    align-items: center;
    padding-left: 2%;
  }
  .ltd-selector {
    padding-top: 3%;
  }
}

.forgot-overlay {
  position: absolute;
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--window);
}

.forgot-overlay h3 {
  color: var(--crit);
}

.forgot-overlay p {
  width: 80%;
  text-align: center;
  font-weight: lighter;
  border: 1px solid gray;
  padding: 4% 2% 4% 2%;
  margin: 20px;
  margin-bottom: 10px;
}

.forgot-overlay a {
  margin: 3px;
}

@media (min-height:400px) and (max-height: 880px) {
  .form-panel {
    max-height: 60%;
  }
  .form-panel .panel-header, .form-panel .header-icon {
    min-height: 50px;
    max-height: 50px;
    border-top-left-radius: 5px;
  }
  .form-panel .panel-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .form-panel .header-icon img {
    height: 40px;
  }
}