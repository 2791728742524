.util-main {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.app-header {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
}

.header-logo {
    width: 225px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-logo small {
    border-top: 2px solid var(--breezi-blue);
    margin-top: 5px;
}

.header-logo img {
    height: 50px;
    margin-top: 7px;
}

.util-header {
    width: 700px;
    max-width: 100%;
    height: 80px;
    background: var(--breezi-recessive);
    border-bottom: 5px solid var(--breezi-blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25px;
}

.header-third {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-third img {
    height: 40px;
}

.header-third small {
    margin: 2px;
}

.header-count {
    display: flex;
    flex-direction: row;
}

.header-count h1 {
    margin: 0px;
    font-size: 3.5em;
    font-weight: 400;
}

.header-count h2 {
    margin: 4px;
    text-align: left;
    width: 50%;
    line-height: 22.5px;
    font-weight: 400;
}

.header-alerts {
    flex-direction: row;
    justify-content: center;
}

.header-alerts h2 {
    margin: 6px;
    font-size: 1.75em;
}

.header-fnc {
    width: 250px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fnc-node {
    width: 42.5px;
    height: 42.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid var(--white);
    margin: 0 13px 0 13px;
    cursor: pointer;
}

.fnc-node img {
    height: 25px;
}

.util-nav {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    left: 0;
}

.nav-node {
    height: 80px;
    width: 80px;
    margin: 4px 0 4px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: 5px solid var(--white);
    cursor: pointer;
    position: relative;
}

.nav-node img {
    width: 40px;
    position: absolute;
}

.nav-node h5 {
    width: 100px;
    background: var(--window);
    position: relative;
    right: 95px;
    border: 2px solid var(--white);
    padding: 4px 0 4px 0;
    z-index: -5;
    animation: slidenavtext .2s forwards;   
    animation-delay: .5s;
}

@keyframes slidenavtext {
    100% {
        right: -95px;
    }
}

.inactive-nav-node {
    width: 10px;
    border-right: 5px solid darkgray;
}

.priority {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.priority small {
    width: 40%;
    height: 53px;
    background: var(--sec);
    display: flex;
    justify-content: center;
    align-items: center;
}

.priority-selector {
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--white);
}

.pri-sel-node {
    width: 33.3%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.team {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.team-header {
    width: 90%;
    height: 40px;
    border-bottom: 2px solid var(--sec);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
}

.team-icon {
    background: var(--sec);
    width: 20%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-icon img {
    width: 40px;
}

.team-body {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    border: 2px solid var(--sec);
    border-top: none;
    width: 90%;
    margin-bottom: 80px;
}

.helper-overlay {
    z-index: 1000;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
}

.helper-panel {
    width: 500px;
    max-width: 100vw;
    height: 450px;
    max-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.helper-header {
    width: 60px;
    height: 100%;
    background: var(--breezi-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.helper-icon {
    position: absolute;
    right: -50px;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 10px solid var(--breezi-blue);
    border-radius: 100%;
    background: var(--breezi-recessive);
}

.helper-icon img {
    width: 45px;
}

.helper-body {
    width: 65%;
    height: 85%;
    margin-left: 60px;
    text-align: left;
    position: relative;
}

.helper-body h2 {
    color: var(--breezi-blue);
    text-decoration: underline;
}

.helper-body small {
    color: var(--cancel);
    align-self: flex-end;
    margin-left: 80%;
    cursor: pointer;
    position: absolute;
    bottom: 0;
}

.helper-body small:hover {
    color: lightblue;
}

.util-tog-outer {
    width: 80%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10%;
}

.toggle-title {
    width: 50%;
    font-size: .7em;
    text-align: left;
}

.util-tog-contianer {
    width: 40%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--breezi-blue);
    position: relative;
    background: gray;
}

.util-tog-contianer small {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .6em;
    cursor: pointer;
}

.util-tog-switch {
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    background: rgba(0, 153, 191, 0.3);
}

.modify-setpoints {
    width: 30%;
    max-width: 500px;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 700px;
}

.install-container {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20%;
}

.install-container button {
    width: 150px;
    height: 50px;
    background: var(--breezi-blue-transparent);
    border: 2px solid var(--breezi-blue);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--white);
    font-family: Comfortaa, sans-serif;
    font-size: 1.2em;
}



@media(max-width:767px) {
    .header-logo, .header-fnc {
        display: none;
    }
    .util-header {
        width: 100%;
        margin: 0;
        padding-top: 20px;
    }
    .header-third {
        margin: 0;
        width: 25%;
    }
    .ent-third img {
        /*width: 30px;
        height: 30px;*/
        display: none;
    }
    .ent-third small {
        width: 80px;
        font-size: .9em;
        background: var(--breezi-recessive);
        border-bottom: 1px solid var(--breezi-blue);
        padding: 1%;
        color: var(--breezi-blue);
    }
    .mobile-count {
        margin-left: -10px;
        margin-bottom: 7px;
        height: 20px;
        margin-right: 10px;
    }
    .header-count h1 {
        margin-right: 15px;
        font-size: 2.5em;
    }
    .util-nav {
        position: absolute;
        bottom: 0;
        height: 70px;
        width: 100vw;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: var(--bg);
    }
    .nav-node, .inactive-nav-node {
        width: 20%;
        border-radius: 0;
        border: none;
        margin: 0;
        height: 70px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .mob-settings-tab {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }
    .mob-settings-tab img {
        height: 30px;
    }
    .nav-node {
        min-width: 20%;
        min-height: 70px;
    }
}

.nav-between {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.nav-between img {
    width: 300px;
    max-width: 50%;
}

.nav-between h3 {
    margin: 5px;
    margin-top: 10px;
}

.nav-between p {
    margin: 5px;
    color: #FA5C65;
}

@media (min-height:440px) and (max-height:880px) {
    .app-header, .util-header {
        max-height: 40px;
        font-size: .75em;
    }
    .app-header h1, .app-header h2, .app-header h3, .app-header h4, .app-header h5, .app-header h6, .app-header p, .app-header small {
        line-height: 14px;
        margin: 0;
    }
    .app-header img {
        height: 25px;
    }
    .header-logo {
        padding-top: 5px;
    }
    .header-logo small {
        border: none;
    }
    .ent-third img {
        margin-top: 5px;
        margin-bottom: -2px;
    }
    .fnc-node {
        width: 30px;
        height: 30px;
    }
    .fnc-node img {
        height: 20px;
    }
    .header-count {
        margin-top: 5px;
    }
    .nav-node {
        max-width: 50px;
        height: 50px;
    }
    .nav-node img {
        height: 50%;
        width: auto;
    }
    .nav-node h5 {
        display: none;
    }
    .ent-third {
        flex-direction: row;
        padding-top: 4px;
        justify-content: center;
        align-items: center;
        width: 30%
    }
    .ent-third small {
        margin: 0;
        
    }
    .ent-third img {
        margin-top: 0px;
        margin: 0;
        margin-right: 10px;
    }
    .header-count h1 {
        margin-right: 3px;
    }
}

@media (min-height:440px) and (max-height:700px) {
    .ent-third small {
        margin: 0;  
    }  
    .ent-third img {
        margin-top: 0px;
        margin: 0;
        margin-right: -10px;
    } 
}