.datapoint-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.datapoint-row {
    width: 85%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.lg-dp-row {
    height: 50%;
    margin: 10px 0 10px 0;
}

.dp-panel {
    height: 100%; 
    background: var(--window);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dp-env-panel {
    width: calc(75% - 5px);
    border: 2px solid var(--tri);
    justify-content: space-around;
    align-items: center;
    position: relative;
    background: var(--bg);
}

.dp-quart {
    width: calc(25% - 10px);
}

.dp-info-quart {
    flex-direction: column;
    justify-content: flex-start;
    border: 2px solid gray;
    height: calc(100% + 10px);
    border-bottom: none;
    z-index: 10;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.dp-trend-panel {
    width: 100%;
    position: relative;
    border: 2px solid gray;
    border-top-left-radius: 0px;
}

.dp-trend-panel .dark-overlay {
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
}

.dp-trend-panel .dark-overlay h2 {
    color: var(--cancel);
}

.dp-third {
    width: calc(33.5% - 10px);
}

.dp-panel-header {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    background: var(--breezi-recessive);
}

.dp-icon {
    width: 80px;
    height: 80px;
    background: var(--breezi-blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dp-icon img {
    height: 40px;
}

.dp-info {
    width: calc(100% - 80px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}

.dp-info h3 {
    margin: 2px;
    margin-left: 4%;
    font-size: 1em;
    width: 90%;
    color: var(--breezi-blue);
    font-weight: lighter;
}

.dp-info-body {
    width: 100%;
    height: calc(70% - 90px);
    display: flex;
    align-items: center;
}

.dp-info-node {
    width: 60px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
}

.dp-info-body small {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dp-info-node img {
    width: 40px;
}

.dp-info-node h4 {
    margin: 5px;
}

.dp-reload {
    border: 1px solid var(--breezi-blue);
    cursor: pointer;
    width: 21px;
    height: 21px;
}

.dp-reload img {
    width: 16px;
}

.dp-reload:hover {
    background: var(--breezi-blue-transparent);
}

.dp-meas-half {
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dp-meas-icon {
    width: 30px;
}

.dp-meas-quart {
    width: 90%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 5%;
    align-items: center;
    border: 2px solid gray;
}

.dp-meas-quart .dp-meas-icon {
    align-self: flex-start;
}

.dp-meas-quart h3 {
    margin: 0;
}

.white-sep {
    background: var(--white);
}

.inline-dp-meas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inline-dp-meas img {
    align-self: center;
    width: 20px;
}

.inline-dp-meas h5 {
    margin: 5px;
    color: gray;
}

.dp-lg-icon {
    width: 50px;
    align-self: flex-end;
    margin-right: 8px;
}

.dp-meas-half h2 {
    margin: 0px;
}

.dp-meas h5 {
    margin: 5px;
}

.dp-util-panel {
    justify-content: flex-start;
    background: var(--bg);
}

.dp-util-header {
    height: 100%;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dp-util-header h5 {
    transform: rotateZ(-90deg);
    width: 300px;
}

.dp-util-body {
    width: calc(100% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dp-alert {
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 10px;
    border-radius: 5px;
}

.dp-alert img {
    width: 30px;
}

.dp-alert small {
    position: absolute;
    bottom: -40px;
}

.dp-equip-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 5px solid gray;
}

.dp-equip-info h3 {
    min-width: 110px;
    margin: 0;
}

.dp-info h3 {
    width: 95%;
}

.dp-equip-info small {
    border-bottom: 1px solid var(--white);
    padding-bottom: 10px;
    margin-bottom: 13px;
    margin-top: 5px;
    color: var(--breezi-blue);
}

.dp-equip-info img {
    height: 32.5px;
}

.dp-trend-controls {
    width: calc(25% - 10px);
    height: 60px;
    position: absolute;
    top: -55px;
    left: 0;
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dp-trend-tiers {
    width: 330px;
    max-width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.dp-trend-tog {
    width: 32%;
    height: 40px;
    background: var(--bg);
    border: 1px solid var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.dp-trend-tog img {
    height: 40%;
}

.active-trend-tog {
    background: var(--sec-transparent);
    cursor: pointer;
}

.active-trend-tog img {
    height: 50%;
    margin-top: 3px;
}

.dp-trend-tog h5 {
    margin: 3px;
}

.dp-trend-nav {
    width: 330px;
    max-width: 95%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.trend-nav-node {
    width: 49%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--white);
    border-radius: 5px;
    background: var(--bg);
    cursor: pointer;
}

.active-trend-nav-node {
    background: var(--sec-transparent);
}

.equip-unassigned {
    width: 50%;
    display: flex;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
}

.equip-unassigned img {
    width: 70px;
}

.equip-unassigned h5 {
    width: 175px;
    margin: 10px;
}

.dp-alerts-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
}
.dp-alerts-body .dp-card-alert {
    margin: 0;
}


/*FOR MOBILE*/
@media(max-width:767px) {
    .mob-dp-main {
        margin-top: 20px;
    }
    .card-frame {
        width: 100vw;
        /* height: 530px;
        max-height: 85%; */
        height: 90%;
        margin-top: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 2%;
    }
    .card {
        width: 385px;
        margin: 0px;
        height: 100%;
    }
    .env-rdg-content {
        min-height: 60%;
    }
    .card-rdg-rdgs {
        height: 100%;
    }
    .card-rdg-row {
    }
}

@media (min-height: 400px) and (max-height: 880px) {
    .dp-trend-controls {
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }
    .dp-trend-tog, .trend-nav-node {
        min-height: 20px;
        max-height: 20px;
    }
    .dp-trend-tiers, .dp-trend-nav {
        margin: 0px;
    }
    .active-trend-tog img {
        display: none;
    }
    .dp-info-node, .dp-info-body small {
        margin-top: 15px;
        font-size: .7em;
    }
    .dp-info-node img  {
        height: 15px;
    }
    .dp-info-body {
        justify-content: center;
    }
}

.dp-maint {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dp-maint img {
    height: 70px;
    margin: 8px;
}

.dp-maint small {
    line-height: 17.5px;
}

.maint-node-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.maint-node-half {
    width: 42.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.maint-node-half h4, .maint-node-half small {
    width: 80%;
    text-align: left;
    margin: 2px;
}
.maint-node-equip-title {
    color: var(--equip);
}
.maint-initials {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-blue);
    margin-right: 10px;
}
.maint-initials h2 {
    font-weight: lighter;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 52.5%;
    font-size: 1.4em;
}

@media (min-height: 1200px) {
    .dp-trend-controls {
        top: -17%;
        height: 120px;
    }
    .dp-trend-tiers {
        height: 100%;
        width: 500px;
        margin-bottom: 12px;
    }
    .dp-trend-tog {
        height: 80px;
        width: 32%;
    }
    .dp-trend-nav {
        width: 500px;
    }
    .trend-nav-node {
        height: 40px;
    }
    .dp-info-body {
        font-size: 1.3em;
        margin-top: -10px;
    }
}