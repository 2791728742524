document.body {
  cursor: not-allowed !important;
}

body {
  margin: 0;
  background: var(--crit);
  font-family: Comfortaa;
  background: var(--bg);
  overflow: hidden;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg: #151920;
  --bg-frame: #3c485d;
  --white: #dcdede;
  --white-transparent: rgba(220, 222, 222, 0.45);
  --breezi-blue: rgb(0, 153, 191);
  --breezi-blue-transparent: rgba(0, 153, 191, 0.45);
  --breezi-blue-glass: rgba(0, 153, 191, 0.1);
  --breezi-recessive: #2c5a67;
  --dark: #151920;
  --prim: rgb(97, 214, 73);
  --prim-transparent: rgba(97, 214, 73, 0.45);
  --prim-glass: rgba(97, 214, 73, 0.15);
  --sec: rgb(29, 77, 168);
  --sec-transparent: rgba(29, 77, 168, 0.45);
  --sec-glass: rgba(29, 77, 168, 0.75);
  --tri: rgb(100, 54, 166);
  --tri-transparent: rgba(100, 54, 166, 0.35);
  --cancel: rgb(210, 144, 44);
  --cancel-transparent: rgba(210, 144, 44, 0.55);
  /* --window: rgb(64, 74, 83); */
  --window: #333237;
  --crit: rgb(187, 73, 73);
  --crit-transparent: rgba(187, 73, 73, 0.55);
  --crit-glass: rgba(187, 73, 73, 0.75);
  --teal-util: rgb(75, 143, 140);
  --teal-util-transparent: rgba(75, 143, 140, 0.35);
  --orange-util: rgb(245, 166, 91);
  --orange-util-transparent: rgba(245, 166, 91, 0.5);
  --brick-util: rgb(139, 99, 92);
  --brick-util-transparent: rgba(139, 99, 92, 0.5);
  --ylw: rgb(171, 177, 52);
  --ylw-transparent: rgba(171, 177, 52, 0.5);
  --dark-blue: #1A2839;
  --gray: gray;
  --equip: #4da67e;

  --onoff-band: #525058;

  /* Monthly Report PDF Export colors */
  --report-l-blue: #2C5A67;
}

::-webkit-scrollbar {
  display: none;
  width: 2px;
  background: none;
  position: absolute;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

img {
  animation: skipflicker .2s forwards;
  opacity: 0;
}

@keyframes skipflicker {
  100% {
    opacity: 1;
  }
}

.highcharts-point {
  cursor: pointer;
}

.fadeelem {
  opacity: 0;
  animation: fadeelement .25s forwards;
  animation-delay: .2s;
}

@keyframes fadeelement {
  100% {
    opacity: 1;
  }
}