.drawer-panel {
    position: absolute;
    width: 475px;
    height: 80px;
    animation: drawer-out .5s forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 2px solid var(--sec);
    z-index: -1;
}

.drawer-panel .btns {
    border: none;
    height: 100%;
    padding: 0px;
}

@keyframes drawer-out {
    0% {
        bottom: 0;
    }
    100% {
        bottom: -80px;
    }
}

@media (max-width: 767px) {
    .drawer-panel {
        /* animation: mobile-drawer-out .5s forwards; */
        z-index: 2;
        /* margin-top: 100%; */
        position: absolute;
        top: 100%;

        /* display: none; */
    }
}