.zone-panel {
    width: 80%;
    height: 90%;
    position: relative;
}

.zone-header {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: var(--breezi-recessive);
    border-bottom: 3px solid var(--white);
    position: relative; 
    margin-bottom: 10px;
}

.zone-header-node {
    width: 13%;
    height: 85%;
    border: 1px solid var(--white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--bg);
    cursor: pointer;
}

.zone-header-node:hover {
    background: var(--breezi-blue);
}

.zone-header-node img {
    height: 40%;
}

.zone-header-node h3 {
    margin: 4px;
    font-size: 1em;
}

.zone-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 1%;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 200px;
}

.invis-zone-header-node {
    width: 13%;
    height: 85%;
}

.hvac-panel {
    width: 65%;
}

.hvac-header {
    height: 70px;
    justify-content: flex-start;
}

.equip-icon {
    width: 70px;
    height: 70px;
    background: var(--equip);
    border-right: 2px solid var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.equip-icon img {
    height: 80%;
}

.equip-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-srtart;
    margin-left: 20px;
}

.equip-title h3, .equip-title small {
    margin: 0;
    text-align: left;
}

.equip-title small {
    width: 100%;
    color: var(--equip);
    border-top: 2px solid var(--equip);
    margin-top: 3px;
    padding-top: 3px;
}

.multi-header-node {
    width: 10%;
    min-width: 125px;
    height: 110px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* margin-bottom: 10px; */
    /* border-left: 1px solid var(--white);
    padding-left: 1%; */
}

.half-header-node {
    width: 50px;
    height: 50px;
    margin: 2%;
    /* border-radius: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--white);
    cursor: pointer;
}

.half-header-node img {
    height: 27.5px;
}

.half-header-node h6 {
    font-size: .6em;
}

.zone-served-by {
    width: 100%;
    height: 27.5px;
    position: absolute;
    bottom: -27.5px;
    background: var(--breezi-blue);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.zone-served-by small {
    margin-left: 2%;
    color: lightgray;
}

.zone-served-by:hover small{
    color: var(--prim);
}

.assign-equip {
    /* margin-top: 10%;
    height: 70%; */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--breezi-blue);
}

.zone-bldg {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.zone-bldg-floor {
    width: 90%;
    height: 25px;
    border: 1px solid var(--white);
    margin-top: 5px;
    background: gray;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.zone-bldg h6, .assign-equip small {
    font-size: .9em;
}

.zone-bldg .ground-level {
    margin-top: 5px;
    width: 100%;
    height: 2px;
    background: var(--prim);
    border: none;
}

.zone-equips {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.zone-equip-node {
    max-width: 70%;
    min-width: 200px;
    height: 60px;
    margin: 1%;
    border: 1px solid var(--breezi-blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.zone-equip-node:hover {
    background: var(--breezi-blue);
}

.zone-equip-node-head {
    height: 100%;
    background: var(--breezi-recessive);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
}

.zone-equip-node img {
    height: 30px;
}

.zone-equip-node-body {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: .8em;
}

.zone-equip-node small {
    margin: 4px;
}



/*FOR MOBILE ZONE COMPONENT*/

.mobile-zone-main {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
}

.mobile-zone-panel {
    width: 92%;
    height: 75%;
/*    background: var(--window);*/
    padding: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-left: 2.5%;

}

.mob-zone-dp {
    width: 60px;
    height: 60px;
    background: red;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

.mob-zone-dp img {
    height: 50%;
}

.mob-zone-dp h5 {
    margin: 5px;
}

/* .mob-zone-dp {
    width: 185px;
    height: 280px;
    border: 1px solid var(--white);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.mob-dp-header {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.mob-zone-dp h2 {
    height: 30px;
    font-size: 1.1em;
    width: calc(100% - 22px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.mob-dp-header img {
    height: 22px;
    padding: 4px;
}

.mob-dp-body {
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.mob-dp-meas {
    width: 90%;
    height: 50px;
    margin: 2px;
    display: flex;
    align-items: center;
}

.mob-dp-icon {
    width: 32.5px;
    height: 32.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.mob-dp-icon img {
    height: 20px;
}

.mob-dp-status {
    width: 80%;
    height: 2px;
    display: flex;
    justify-content: flexs-start;
    align-items: center;
    margin-left: 6px;
}

.mob-dp-status-node {
    width: 36px;
    height: 15px;
    margin: 3px;
    border-radius: 5px;
}

.mob-dp-updated {
    width: 100%;
    height: 20px;
    background: var(--breezi-recessive);
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .7em;
} */

.zone-settings-overlay {
    top: 121px;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
}

.zone-settings {
    width: 450px;
    height: 80%;
    background: var(--window);
    align-self: center;
    position: absolute;
    top: -100%;
    right: 2%;
    animation: fallin .5s linear forwards;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid var(--white);
}

.zone-settings .panel-header {
    background: var(--sec-transparent);
}

.zone-settings .panel-header .header-title {
    width: 100%;
}

.zone-settings .panel-header h2 {
    font-weight: lighter;
    text-decoration: none;
    text-align: center;
}

@keyframes fallin {
    100% {
        top: 2.5%;
    }
}

.zone-settings-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.zone-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 85%;
}

.zone-cards {
    width: 92.5%;
    height: 95%;
    margin-right: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
}

.zone-mini-card {
    width: 200px;
    height: 200px;
    background: var(--window);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
}

.mini-status {
    width: 100%;
    height: 5px;
}

.zone-mini-header {
    width: 90%;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid gray;
}

.zone-mini-card h5 {
    color: var(--breezi-blue);
    font-size: .9em;
    margin-bottom: 15px;
}

.zone-mini-row {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 5px 0;
}

.zone-mini-rdg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2%;
    width: 30%;
    height: 45px;
    border-radius: 4px;
}

.zone-mini-rdg img {
    height: 30px;
}

.temphum-mini-rdg {
    margin-top: 10px;
    width: 65px;
    height: 65px;
    border-radius: 100%;
}

.ghost-mini-card {
    width: 198px;
    height: 198px;
    border: 2px dashed var(--white);
    opacity: .3;
    justify-content: center;
}

.ghost-mini-card img {
    width: 50%;
    margin-bottom: 10px;
}

.zone-mini-card label {
    margin-top: 3px;
    text-align: left;   
    width: 85%;
    font-size: .8em;
    font-weight: lighter;
    color: gray;
}

.zone-mini-card input {
    margin-top: 10px;
    background: none;
    border: 1px solid gray;
    font-size: 1em;
    text-align: left;
    color: var(--white);
}

.zone-mini-card .btn {
    margin-top: -8px;
    width: 85%;
    height: 30px;
}

.zone-grid-tog {
    width: 80px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    right: 10px;
}

.close-sleeve:hover {
    border: 1px solid var(--crit);
}

.zone-ap-bank {
    overflow-x: hidden;
    overflow-y: scroll;
    flex-wrap: wrap;
}

.zone-ap-bank .equip-ap {
    margin: .5%;
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
}

.zone-sp-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
}

.zone-sp-overlay .card-frame {
    margin-top: 50px;
    border-radius: 5px;
}

.zone-sp-overlay .card-face {
    width: 395px;
    border: 1px solid var(--white);
    overflow: hidden;
}

.zone-hier {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
}

.zone-hier h4 {
    margin: 5px;
    font-weight: lighter;
}

.zone-hier span {
    color: var(--breezi-blue);
}

@media (min-height: 400px) and (max-height: 880px) {
    .equip-panel {
        min-height: 85vh;
        max-height: 85vh;
    }
    .grid-tog {
        min-height: 25px;
        max-height: 25px;
    }
    .sleeve-node {
        max-height: 20px;
        min-height: 20px;
    }
    .sleeve-node img {
        min-height: 80%;
        max-height: 80%;
    }
    .hvac-cards {
        min-height: 95%;
        max-height: 95%;
    }
    .airflow {
        min-height: 25px;
        max-height: 25px;
    }
    .zone-panel {
        min-height: 85vh;
        max-height: 85vh;
    }
    .airflow-static {
        height: 20px;
    }
    .zone-panel {
        margin-top: 20px;
    }
    .zone-cards .card-frame, .zone-cards .card-body, .zone-cards .card {
        max-height: 500px;
    }
}

@media (min-height:1200px) {
    .zone-panel {
        font-size: 1.3em;
    }
}

