
.landing-main {
    background-image: url('https://brebe-s3-storage-matt.s3-eu-west-1.amazonaws.com/breezi-pro/city_center+copy.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 0;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.landing-overlay {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0;
    height: 100vh;
}

.landing-sm-col {
    width: 15%;
}

.landing-lg-col {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lang-col {
    justify-content: flex-end;
    position: absolute;
    left: 0;
}

.landing-lg-col section {
    justify-content: center;
}

.landing-sm-col h3 {
    border-bottom: 2px solid var(--breezi-blue);
    padding: 4px;
    cursor: pointer;
}

.lang-col h3:hover {
    background: var(--breezi-blue);
    border: 2px solid var(--white);
}

.landing-lg-col section img {
    width: 400px;
}

.landing-lg-col section h4 {
    margin: 0px;
    width: 400px;
    text-align: center;
    margin-bottom: 7px;
}

.cookie-setting {
    width: 500px;
    max-width: 100vw;
    height: 120px;
    position: absolute;
    bottom: 10px;
    background: var(--tri);
    left: calc(50vw - 250px);
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.cookie-setting:hover {
    background: gray;
}

.cookie-setting img {
    height: 60px;
    margin-left: 10px;
}

.cookie-setting p {
    font-size: .9em;
    text-align: left;
    margin-left: 10px;
    margin-right: 5px;
}

.landing-logo {
    /* min-width: 500px;
    max-width: 90vw; */
}

@media(max-width:850px) {

    .cookie-setting {
        left: 10px;
        right: 10px;
        width: auto;
        margin: 0;
        flex-direction: column;
        height: 250px;
        max-height: 40%;
        justify-content: center;
    }

    .cookie-setting p {
        width: 85%;
    }

    .landing-main {
        max-width: 100vw;
        min-width: 100vw;
        max-height: 100vh;
        min-height: 100vh;
        overflow: hidden;
    }
    .landing-overlay {
        max-width: 100vw;
        min-width: 100vw;
        max-height: 100vh;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
    }
    .col {
        height: 0;
        flex-basis: 95%;
        justify-content: center;
        align-items: center;
    }
    .lang-col {
        display: none;
    }
    .landing-lg-col {
        min-height: 300px;
        max-height: 300px;
    }
    .landing-sm-col {
        min-height: 200px;
        max-height: 200px;
    }
    .title-col {
        width: 90vw;
        margin-bottom: 20%;
    }
    .landing-logo {
        padding-left: 5%;
        padding-right: 5%;
        margin: 5px;
        width: 200px;
    }
    .title-col .btns {
        border: none;
        width: 95vw;
    }
    .landing-main .form-panel {
        max-height: 400px;
        min-height: 400px;
    }
    .landing-main .drawer-panel {
        animation: mobiledrawer .25s forwards;
        width: 92.5vw;
    }
    @keyframes mobiledrawer {
        100% {
            bottom: 90px;
        }
    }
}

.forgot-pw-init {
    z-index: 100;
    position: absolute;
    bottom: 10%;
}