.organization-main {
    justify-content: center;
    align-items: center;
}

.organization-bldgs {
    width: 80%;
    height: 85%;
    margin-top: 20px;
    border-top: 1px solid lightblue;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
}

.bldg-card {
    cursor:pointer;
}

.bldg-card:hover {
    border: 1px solid var(--breezi-blue);
}

.bldg-header-img {
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-top-right-radius: 100%;
    border-bottom-right-radius: 100%; */
    background: var(--breezi-blue);
    /* border: 1px solid var(--white); */
}

.bldg-header-img img {
    width: 40px;
}

.bldg-card-loc {
    width: 23%;
    height: 100%;
    padding: 0 10px 0 10px;
    /* border-left: 1px solid var(--white); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: var(--breezi-blue); */
}

.bldg-card-loc img {
    height: 25px;
    margin: 4px;
}

.bldg-card-loc small {
    font-size: .7em;
    margin: 2px;
}

.bldg-header-title {
    width: calc(96% - 102px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* flex-direction: column;
    justify-content: center;
    align-items: flex-start; */
    margin-left: 3%;
}

.bldg-header-title h3 {
    width: 100%;
    text-align: left;
    margin: 0px;
    color: var(--breezi-blue);
    padding-left: 8px;
    font-size: .9em;
    font-weight: lighter;
}

.bldg-header-title small {
    width: 100%;
    text-align: left;
    margin: 0px;
    color: lightblue;
    /* border-top: 1px solid var(--white); */
    padding-left: 9.5px;
    padding-top: 6px;
}

.averages {
    width: 41%;
    text-align: right;
    position: absolute;
    /* font-weight: lighter; */
    font-size: .9em;
    color: gray;
    /* color: var(--brick-util); */
    /* border-bottom: 1px solid var(--brick-util);
    /* color: lightblue;
    border-bottom: 1px solid lightblue; */
    top: 82px;
    right: 7%;
    padding-bottom: 3px;
}

.card-rdg {
    width: 85%;
    height: 52.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 7px 0 7px 0;
    padding-right: 10px;
    overflow: hidden;
    /* border: 1px solid var(--white); */
    background: rgba(0, 0, 0, 0.45);
    border-radius: 5px;
}

.rdg-icon {
    width: 52.5px;
    height: 52.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-blue-transparent);
    /* border-right: 1px solid var(--white); */
}

.rdg-icon img {
    height: 25px;
}

@media(max-width:850px) {
    .organization-bldgs {
        width: 92vw;
    }
    .mobile-bldg-card {
        width: 100%;
        height: 155px;
        background: var(--window);
        margin-top: 10px;
        border-radius: 5px;
        overflow: hidden;
        padding: 1px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .mobile-bldg-card-body {
        width: 98%;
        height: 60%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .mob-rdg {
        width: 42.5px;
        height: 42.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--breezi-recessive);
        border-radius: 100%;
        border: 5px solid var(--bg);
        z-index: 2;
    }
    .mob-rdg img {
        height: 60%;
    }
    .mobile-ob-bldg-card {
        width: 100%;
        height: 155px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--window);
        margin-top: 10px;
        border-radius: 5px;
    }
    .mobile-ob-bldg-card img {
        height: 40px;
        margin-bottom: 5px;
        padding: 2%;
        background: var(--breezi-blue);
        border-radius: 5px;
        border-bottom-right-radius: 0;
    }
    .mobile-ob-bldg-card h1 {
        width: 220px;
        height: 40px;
        padding: 1.1%;
        font-size: 1.2em;
        font-weight: lighter;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid var(--breezi-blue);
        margin-left: -2px;
    }
    .mobile-bldg-connector {
        width: 80%;
        height: 5px;
        background: var(--bg);
        position: absolute;
    }
}




/* FOR ORG_CARD OVERHAUL */

.org-card-body {
    width: 95%;
    height: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    /* background: var(--dark-blue); */
    border-radius: 5px;
    /* border: 2px solid gray; */
}

.org-card-half {
    width: 100%;
    height: calc(50% - 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.org-card-quart {
    width: calc(50% - 2px);
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.org-card-half h4 {
    position: absolute;
    top: 12.5px;
    left: 2.5%;
    padding-left: 5px;
    padding-right: 5px;
    color: var(--equip);
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: lighter;
}

.org-path {
    width: 92.5%;
    text-align: left;
    color: gray;
    padding: 2px;
    border-top: 1px solid gray;
    align-self: flex-start;
    margin-left: 3.5%;
}

.org-card-maint {
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: .9em
}

.org-card-maint img {
    height: 30px;
}

.org-card-tmp-hum {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    overflow: hidden;
    font-size: .8em;
    /* margin: 10px; */
}

.org-card-tmp-hum-head {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
}

.org-card-tmp-hum img {
    height: 70%;
}

.org-card-tmp-hum h3 {
    margin-top: 5%;
    font-weight: lighter;
}

.org-card-equip {
    /* border: 2px solid var(--equip); */
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 7%;
}

.org-card-filter {
    border-right: 2px solid gray;
}

.org-card-rdg {
    width: 45%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    border-radius: 5px;
    overflow: hidden;
}

.org-card-rdg-head {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--breezi-blue);
}

.org-card-rdg img {
    height: 60%;
}

.org-card-rdg h3 {
    width: calc(100% - 50px);
    font-weight: lighter;
}

/* .org-card-body h4 {
    margin: 0px;
    font-weight: lighter;
    color: var(--breezi-blue);
} */

.org-card-env {
    flex-direction: column;
    /* border: 2px solid var(--breezi-blue); */
    border-radius: 5px;
    padding: 0;
    padding-top: 25px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    height: 50%;
}

.org-card-env h4 {
    color: var(--breezi-blue);
    background: none;
    top: 7px;
}

.org-card-env .org-path {
    width: 100%;
}

.org-card-rdg-set {
    width: 90%;
    height: 37.5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-right: 10px;
}

.org-card-rdg-set .org-card-rdg {
    width: 45%;
}

.supply-ret {
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
    height: 100%;
}

.supply-ret h5 {
    transform: rotate(-90deg);
    margin: 0px;
    color: var(--window);
}

.monthly-icon {
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 0px;
    right: 10px;
    cursor: pointer;
    padding: 3px;
    border: 2px solid rgba(0, 0, 0, 0);
}

.faux-report {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: -80px;
    background: var(--bg);
    z-index: 100;
}

/* .monthly-icon:hover {
    background: var(--breezi-blue);
    border: 2px solid var(--white);
} */

.monthly-icon img {
    width: 70%;
    height: auto;
}


@media (max-width: 767px) {
    .org-card {
        min-height: 300px;
        max-height: 25vh;
        min-width: 92.5vw;
        max-width: 92.5vw;
    }
    .org-card-body {
        height: 100%;
        justify-content: space-between;
    }
    .org-card-rdg-set {
        min-width: 45%;
        max-width: 45%;
        margin: .5%;
        height: 80%;
        margin-top: 20px;
        border: none;
    }
    .org-card-maint {
        width: 42.5px;
        height: 42.5px;
    }
    .org-card-tmp-hum {
        width: 35px;
        height: 35px;
        margin: 1px;
    } 
    .org-card-env {
        min-height: 30%;
        max-height: 30%;
        justify-content: space-between;
    }
    .org-card-env h4 {
        top: -5px;
    }
    .supply-ret {
        min-width: 20px;
        justify-content: center;
        align-items: center;
    }
    .org-env-rdg {
        width: 45px;
        height: 45px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .org-env-rdg img {
        height: 80%;
    }
    .supply-ret {
        width: 15px;
    }
    .org-card-half {
        margin: 0;
        min-height: 80px;
        max-height: 80px;
        flex-direction: row;
        overflow: visible;
    }
    .monthly-icon {
        display: none;
    }
    .org-card-sep {
        display: none;
    }
    .organization-bldgs {
        margin-top: 90px;
        padding-top: 10px;
    }
    .org-card-rdg-set {
        min-width: 47.5%;
    }
}

.org-card-sep {
    width: 90%;
    height: 1px;
    background: gray;
    margin-bottom: 10px;
    margin-top: 10px;
}

@media (min-height: 1200px) {
    .org-card-maint {
        width: 100px;
        height: 100px;
        max-height: 90%;
    }
    .org-card-maint img {
        height: 40%;
    }
    .org-card-tmp-hum {
        width: 90px;
        height: 90px;
    }
    .org-card-rdg {
        height: 70px;
    }
    .org-card-rdg-head {
        width: 70px;
        height: 70px;
    }
    .org-card-rdg-head img {
        height: 50%;
    }
    .org-card-env {
        align-content: center;
    }
    .org-card-rdg-set {
        font-size: 1.25em;
    }
    .bldg-header-title {
        max-width: 45%;
    }
}

@media (min-height:400px) and (max-height:880px) {
    .org-card-half {
        height: 50%;
        padding: none;
    }
    .org-card-rdg {
        max-height: 45px;
    }
}