.bldg-main {
    justify-content: center;
    align-items: center;
}

.bldg-container {
    width: 700px;
    max-width: 95vw;
    max-height: 85%;
    background: var(--window);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 500px;
    position: relative;
    border: 2px solid var(--breezi-blue);
}

.bldg-container .header-icon {
    flex-direction: column;
}

.bldg-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 5%;
}

.bldg-floors {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px 0 10px 0;
    position: relative;
}

.bldg-floor {
    width: 80%;
    min-height: 44px;
    max-height: 44px;
    /* border: 1px solid var(--white); */
    border-radius: 5px;
    margin: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: gray;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.bldg-floor:hover {
    background: var(--white);
    color: gray;
}

.bldg-floor h3 {
    margin-left: 30px;
    font-weight: lighter;
    font-size: 1em;
}

.rooftop-bldg-floor {
    border: 2px dashed gray;
    background: rgba(0, 0, 0, 0.2);
}

.ground-level {
    width: 90%;
    height: 17px;
    /* border: 2px solid var(--prim-transparent); */
    background: var(--breezi-recessive);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}

.bldg-header-loc {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* border-top: 1px solid var(--white); */
}

.bldg-header-loc img {
    height: 15px;
    margin-top: 6px;
}

.bldg-header-loc small {
    border: none;
}

.bldg-container .header-icon img {
    height: 40px;
}

.bldg-weather {
    width: 120px;
    height: 100%;
    /* border-left: 1px solid var(--white); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bldg-weather img {
    margin-top: 10px;
}

.bldg-settings {
    width: 70px;
    height: 96%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bldg-floor-overlay {
    width: 100%;
    height: 40px;
    border: 2px solid var(--white);
}

.bldg-floor-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bldg-floor-overlay small {
    color: var(--breezi-blue);
    margin-left: 2px;
}

.bldg-floor-overlay input {
    height: 61%;
    font-size: 1.2em;
    background: none;
    border: none;
    border: 1px solid var(--white);
    color: var(--white);
    margin-left: 10px;
    margin-right: 5px;
}

.bldg-floor-overlay .btn {
    height: 60%;
    width: 100px;
    border-radius: 0;
    margin: 2px;
}

.clear-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
}

.bldg-sp-tog, .bldg-setpoints-tog {
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid var(--white); */
    border-radius: 4px;
    cursor: pointer;
}

.bldg-sp-tog:hover {
    background: var(--sec);
}

.bldg-sp-tog img, .bldg-setpoints-tog img {
    width: 25px;
}

.bldg-sp {
    width: 400px;
    height: 500px;
    margin-top: calc(50% - 259px);
    border: 1px solid var(--white);
    background: var(--bg);
    overflow: hidden;
    border-radius: 5px;
}

.active-rooftop {
    background: var(--breezi-blue);
}

.bldg-header-title {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.bldg-header-title img {
    margin-left: 8px;
    margin-bottom: 7px;
    height: 25px;
}

.bldg-setpoints-tog:hover {
    background: var(--tri);
}

.bldg-settings-panel {
    width: 100%;
    height: 30vh;
}

.floor-alert-overlay {
    height: 36px;
    width: 25%;
    min-width: 100px;
    position: absolute;
    right: -50%;
    background: var(--bg);
    opacity: .8;
    animation: slidefloor .25s forwards;
    animation-delay: .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    /* border: 2px solid var(--breezi-blue); */
}

.floor-alert-overlay img {
    height: 80%;
    margin: 10px;
}
.floor-alert-overlay h3 {
    margin: 0;
    font-size: 1.25em;
}

@keyframes slidefloor {
    100% {
        right: 4px;
    }
}

@media(max-width:767px) {
    .bldg-main {
        justify-content: center;
        margin-top: 42px;
    }
    .mobile-bldg-container {
        width: 95vw;
        max-height: calc(100% - 100px);
        background: var(--window);
        margin-bottom: 0;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .bldg-floor {
        width: 90%;
    }
    .ground-level {
        width: 92.5%;
    }
}

@media (min-height: 1200px) {
    .bldg-container {
        width: 60%;
        font-size: 1.2em;
    }
    .bldg-container .panel-header {
        justify-content: flex-start;
    }
    .bldg-floor {
        min-height: 70px;
        max-height: 70px;
    }
    .bldg-header-title {
        margin: 0;
        justify-content: flex-start;
        padding: 0;
        width: 80%;
    } 
    .bldg-settings {
        height: 100px;
        width: 100px;
    }
    .bldg-settings img {
        min-width: 40px;
        max-width: 40px;
        height: auto;
    }
    /* .bldg-header-loc {
        width: 50%;
        justify-content: flex-start;
        margin-left: -50px;
    } */
}