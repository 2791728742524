.snapshot-bars {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
}

.snp-bar {
    height: 100%;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3px;
}

.snp-node {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    border: 2px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: snpto .3s forwards; 
}

@keyframes snpto {
    100% {
        width: 40px;
        height: 40px;
    }
}

.snp-node img {
    height: 70%;
}

.snp-bar-rdg {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid gray;
    margin: 5px;
}

.snp-title {
    position: absolute;
    bottom: 10px;
}

.mini-snap {
    cursor: pointer;
}

.snp-tms {
    position: absolute;
    top: 85px;
}

.snp-onoff {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.snp-nameplate {
    width: 100%;
    height: 100%;
    background: var(--bg);
    position: absolute;
}

.snp-nameplate-body {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.snp-nameplate-info {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.snp-nameplate-item {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 2px solid var(--breezi-recessive);
    margin: 3px;
}

.snp-nameplate-head {
    width: 35%;
    height: 100%;
    background: var(--breezi-recessive);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
    font-size: .8em;
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
}

.snp-nameplate-item p {
    width: 50%;
    text-align: left;
    margin-left: 8%;
}

@media (min-height: 400px) and (max-height: 880px) {
    .snp-bar {
        width: 32.5px;
    }
    .snp-node {
        animation: minisnpto .5s forwards;
    }
    @keyframes minisnpto {
        100% {
            width: 30px;
            height: 30px;
        }
    }
    .snp-nameplate-item {
        height: 20px;
        font-size: .8em;
    }
}

@media (min-height:1200px) {
    .snapshot-bars {
        justify-content: space-around;
        width: 92.5%;
    }
    .snp-node {
        animation: lgsnapto .25s forwards;
    }
    @keyframes lgsnapto {
        100% {
            width: 45px;
            height: 45px;
        }
    }
    .snp-node h5 {
        animation-delay: .5s;
    }
}

@media (min-height: 400px) and (max-height:700px) and (min-width: 1100px) {
    .snp-bar {
        width: 25px;
    }
    .snp-bar-rdg {
        max-width: 20px;
    }
    .floor-zone-title {
        margin-left: 10px;
    }
}